import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react"

import { Button, Checkbox, Col, Drawer, Form, Input, Row, Select, Tooltip } from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox"

import { NumericInput } from "@/components/_Shared/NumericInput"

import { useCustomerClientMutation } from "@/hook/CustomerClients/useCustomerClientMutation"
import { useCustomerClientByIdQuery } from "@/hook/CustomerClients/useCustomerClientQuery"
import { useCustomerCategorySelectListQuery } from "@/hook/Dictionaries/Customers/useCustomerCategoryQuery"
import { useUserSelectListQuery, useUsersQuery } from "@/hook/Dictionaries/useUserQuery"

import { ICustomerClientCreate } from "@/types/ICustomerClient"

import { PHONE_LENGTH } from "@/utils/constants"
import { filterSelectOption } from "@/utils/filterSelectOption"

export interface CustomerClientAddFormProps {
  open(id?: number): void

  close(): void
}

interface CustomerClientAddFormValues {
  name: string
	managerId: string
	phone: string
	customerCategoryId: number
}

export const CustomerClientAddForm = forwardRef<CustomerClientAddFormProps>(
  (props, ref) => {
    const [form] = Form.useForm<CustomerClientAddFormValues>()
    const [id, setId] = useState<number>(0)
    const [isOpen, setIsOpen] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)

    const { data: categories } = useCustomerCategorySelectListQuery()
    const { data: managers } = useUserSelectListQuery("Manager")

    const { data: customerClient } = useCustomerClientByIdQuery(id)
    const { createMutation, updateMutation } = useCustomerClientMutation()

    const onSubmitHandler = () => {
       form.submit()
    }

    useImperativeHandle(ref, () => ({
      open(id?: number) {
        onOpenHandler(id)
      },
      close() {
        onCloseHandler()
      }
    }))

    useEffect(() => {
      if (customerClient && isOpen) {
        const response = customerClient.response

        form.setFields([
          {
            name: "name",
            value: response.name
          },
          {
            name: "managerId",
            value: response.managerId
          },
          {
            name: "phone",
            value: response.phone
          },
          {
            name: "customerCategoryId",
            value: response.customerCategoryId
          }
        ])
      } 
    }, [id, customerClient, form, isOpen])

    const title = useMemo(() => {
      const customerClient = " клиента"
      if (id) {
        return "Редактировать" + customerClient
      }

      return "Добавить" + customerClient
    }, [id])

    const onOpenHandler = (id?: number) => {
      if (id) {
        setId(id)
      }
      form.resetFields()
      setIsOpen(true)
    }

    const onFinish = async (data: CustomerClientAddFormValues) => {
      try {
        const request: ICustomerClientCreate = {
          name: data.name,
          managerId: data.managerId,
          customerCategoryId: data.customerCategoryId,
          phone: data.phone
        }
        setIsProcessing(true)

        if (id) {
          await updateMutation.mutateAsync({
            ...request,
            id: id
          })
        } else {
          await createMutation.mutateAsync(request)
        }

        form.resetFields()
        onCloseHandler()
      } finally {
        setIsProcessing(false)
      }
    }

    const onCloseHandler = () => {
      setId(0)
      form.resetFields()
      setIsOpen(false)
    }

    return (
      <Drawer
        title={title}
        width={820}
        onClose={onCloseHandler}
        open={isOpen}
        styles={{ body: { paddingBottom: 80 } }}
        extra={
          <Button type="primary" disabled={isProcessing} onClick={onSubmitHandler}>
            Сохранить
          </Button>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name="name"
                label="Наименование"
                rules={[
                  { required: true, message: "Пожалуйста введите наименование" }
                ]}
              >
                <Input placeholder="Наименование" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Ответственное лицо"
                name="managerId"
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста выберите ответственное лицо"
                  }
                ]}
              >
                <Select
                  options={managers?.response.map(m => ({
                    value: m.id,
                    label: m.name
                  }))}
                  placeholder="Ответственное лицо" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
            <Form.Item label="Номер телефона" name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Пожалуйста введите номер телефона"
                      },
                      { len: PHONE_LENGTH, message: `Номер телефона должен состоять из ${PHONE_LENGTH} цифр` }
                    ]}
              >
              <NumericInput prefix="+7" type="number" placeholder="Номер телефона" maxLength={PHONE_LENGTH}/>
            </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Категория"
                name="customerCategoryId"
                rules={[{ required: true, message: "Категория обязательное поле" }]}
              >
                <Select
                  placeholder="Категория"
                  allowClear={false}
                  showSearch
                  options={categories?.response}
                  filterOption={(inputValue, option) => filterSelectOption(inputValue, option?.label || "")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    )
  }
)
