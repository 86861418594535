export const paths = {
  main: "/",
  fuels: "/fuels",
  fuelArchive: "/fuel-archive",
  fuelLimits: "/fuel-limits",
  fuelLimitById: (id: number) => `/fuel-limits/${id}`,
  fuelById: (id: number) => `/fuels/${id}`,
  fuelQuoteById: (fuelId: number, fuelQuoteId: number) => `/fuels/${fuelId}/quote/${fuelQuoteId}`,
  fuelTypes: "/fuel-types",
  fuelTypeById: (id: number) => `/fuel-types/${id}`,
  managerAccount: "/manager-account",
  orders: "/orders",
  orderById: (id: number) => `/orders/${id}`,
  orderLogsById: (id: number) => `/orders/${id}/logs`,
  dispatcherOrders: "/dispatcher-orders",
  dispatcherOrderById: (id: number) => `/dispatcher-orders/${id}`,
  dispatcherTimeCoordinate: "/time-coordinate",
  dispatcherTimeCoordinateById: (id: number) => `/time-coordinate/${id}`,
  customers: "/customers",
  customerById: (id: number) => `/customers/${id}`,
  customerClients: "/customer-clients",
  customerClientById: (id: number) => `/customer-clients/${id}`,
  customerCategories: "/customer-categories",
  customerCategoryById: (id: number) => `/customer-categories/${id}`,
  authorizationError: "/authorization-error",
  drivers: "/drivers",
  driverById: (id: number) => `/drivers/${id}`,
  transport: "/transports",
  transportById: (id: number) => `/transports/${id}`,
  users: "/users",
  userById: (id: string) => `/users/${id}`,
  roles: "/roles",
  account: "/account",
  orderReports: "/order-reports",
  ordersHistory: "/order-history",
  ordersHistoryById: (id: number) => `/order-history/${id}`,
  ordersHistoryLogsById: (id: number) => `/order-history/${id}/logs`,
  customerFinance: "/customer-finance",
  finance: "/finance",
  authorities: "/authorities",
  authoritiesById: (id: number) => `/authorities/${id}`,
  deliveryAddresses: "/delivery-addresses",
  deliveryAddressById: (id: number) => `/delivery-addresses/${id}`,
  depots: "/depots",
  notificaitons:"/notifications",
  notificationSettings:"/notification-settings",
  analyticsPrice: "/analytics-price",
  analyticsOrder: "/analytics-order",
  analyticsPurchase: "/analytics-purchase",
  analyticsRefusal: "/analytics-refusal",
  analyticsOffers: "/analytics-offers",
  managerAnalyticsOrder: "/manager-analytics-order",
  years: "/years",

  // Общая аналитика между ролями
  pricesAnalytics: "/analytics-prices",

  // Аналитика для клиента (Refusal пока под вопросом)
  ordersAnalytics: "/analytics-orders",
  refusalsAnalytics: "/analytics-refusals",
  offersAnalytics: "/analytics-offers",

  // Аналитика менеджера и админа
  salesAnalytics: "analytics-sales",
  limitsAnalytics: "analytics-limits",
  limitsSalesAnalytics: "analytics-limits-sales",
  deliveriesPickupsAnalytics: "analytics-deliveries-pickups",

  customerSettings: "/customer-settings",
  timeUnloadFuels: "/time-unload-fuels"
}
