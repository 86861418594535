import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react"

import {
  Button,
  Col,
  Collapse,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  UploadFile,
  Skeleton,
  Alert,
  TimeRangePickerProps as AntDatePickerProps
} from "antd"
import dayjs from "dayjs"
import { useSearchParams } from "react-router-dom"
import { uid } from "uid"

import { DatePicker } from "@/components/_Shared/DatePicker"
import { NumericInput } from "@/components/_Shared/NumericInput"
import { PicturesUpload } from "@/components/_Shared/PicturesUpload"
import { CustomerContext } from "@/context/CustomerContext"
import { getCustomerClients, getCustomerClientsSelectList } from "@/http/customerClient"

import classes from "./DriverAddForm.module.scss"

import { useDriverByIdQuery } from "@/hook/Dictionaries/useDriverQuery"
import { useDriversMutation } from "@/hook/Dictionaries/useDriversMutation"

import {
  DriverCitizenRussianFederationForSelect,
  DriverTypeIdentityDocumentForSelect,
  IDocumentFile,
  IDriver,
  IDriverCreate,
  IDriverTypeIdentityDocument
} from "@/types/IDriver"
import { ISelectListItem } from "@/types/ui"

import { PHONE_LENGTH } from "@/utils/constants"
import { uploadFileConvert } from "@/utils/uploadFileConvert"


const { Text } = Typography

export interface DriverAddFormProps {
  open(id?: number, isCustomerEdit?: boolean): void

  close(): void
}

interface DriverAddFormValues {
  fullName: string
  firstName: string
  lastName: string
  isCitizenRussian: number
  typeIdentityDocument?: number
  birthDate: string
  birthPlace: string
  patronymic: string
  passportSeries: number
  passportNumber: number
  passportIssuedBy: string
  passportDate: string
  passportDivisionCode?: string
  region: string
  district: string
  settlement: string
  street: string
  house: string
  building: string
  apartment: string
  phone: number
  passportScans?: IDocumentFile[]
  type: IDriver
  clientIds: number[]

  passportScan: number
}

export const DriverAddForm = forwardRef<DriverAddFormProps>((props, ref) => {
  const customerContext = useContext(CustomerContext)

  const [form] = Form.useForm<DriverAddFormValues>()
  const [id, setId] = useState<number>(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isAgeValid, setIsAgeValid] = useState<boolean>(false)
  const [isAgeChosen, setIsAgeChosen] = useState<boolean>(false)
  const [birthdate, setBirthdate] = useState<dayjs.Dayjs | null>(dayjs().subtract(18, "year"))


  const [hasPassportDivisionCode, setHasPassportDivisionCode] = useState(false)

  const [isCustomerEdit, setIsCustomerEdit] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [modalText, setModalText] = useState("")
  const [data, setData] = useState<DriverAddFormValues>()
  const [clients, setClients] = useState<ISelectListItem[]>([])

  const [searchParams, setSearchParams] = useSearchParams()

  const { data: driver, isLoading } = useDriverByIdQuery(id)
  const { createMutation, updateMutation, uploadPassportScansMutation } = useDriversMutation()

  const [fileList, setFileList] = useState<UploadFile[]>([])

  useImperativeHandle(ref, () => ({
    open(id?: number, isCustomerEdit?: boolean) {
      onOpenHandler(id, isCustomerEdit)
    },
    close() {
      onCloseHandler()
    }
  }))

  const formDriverText = useMemo(() => {
    return id === 0 ? "Внести" : "Изменить"
  }, [id])

  useEffect(() => {
    if (driver?.response?.passportScans) {
      const newPromises = driver.response.passportScans.map((file) =>
        uploadFileConvert({
          url: file.content,
          name: file.name,
          uid: uid()
        })
      )
      Promise.all(newPromises).then((res) => {
        setFileList(res)
        form.setFieldValue("passportScan", res.length)
      })
    }
  }, [driver?.response.passportScans])

  useEffect(() => {
    if (isOpen && !customerContext.isCustomer()) {
      getCustomerClientsSelectList().then((res) => setClients(res.response))
    }

    if (driver && isOpen) {
      const response = driver.response

      if (
        IDriverTypeIdentityDocument.RussianPassport ===
        response.typeIdentityDocument
      ) {
        setHasPassportDivisionCode(true)
      }

      form.setFields([
        {
          name: "lastName",
          value: response.lastName
        },
        {
          name: "firstName",
          value: response.firstName
        },
        {
          name: "patronymic",
          value: response.patronymic
        },
        {
          name: "isCitizenRussian",
          value: response.isCitizenRussian ? 0 : 1
        },
        {
          name: "typeIdentityDocument",
          value: response.typeIdentityDocument
        },
        {
          name: "birthDate",
          value:
            response.birthDate.length > 0
              ? dayjs(response.birthDate, "DD/MM/YYYY")
              : null
        },
        {
          name: "birthPlace",
          value: response.birthPlace
        },
        {
          name: "passportSeries",
          value: response.passportSeries
        },
        {
          name: "passportNumber",
          value: response.passportNumber
        },
        {
          name: "passportDivisionCode",
          value: response.passportDivisionCode
        },
        {
          name: "passportIssuedBy",
          value: response.passportIssuedBy
        },
        {
          name: "passportDate",
          value:
            response.birthDate.length > 0
              ? dayjs(response.passportDate, "DD/MM/YYYY")
              : null
        },
        {
          name: "region",
          value: response.region
        },
        {
          name: "district",
          value: response.district
        },
        {
          name: "settlement",
          value: response.district
        },
        {
          name: "street",
          value: response.street
        },
        {
          name: "house",
          value: response.house
        },
        {
          name: "building",
          value: response.building
        },
        {
          name: "apartment",
          value: response.apartment
        },
        {
          name: "phone",
          value: response.phone
        }
      ])

      form.setFieldValue("clientIds", response.clients.map(c => c.id))
      checkAgeDriver()
    }
  }, [id, driver, form, isOpen])

  const title = useMemo(() => {
    if (id) {
      return "Редактировать"
    }

    return "Добавить"
  }, [id])

  const setOrDeleteQueryParams = (name: string, value?: string) => {
    if (value) searchParams.set(name, value)
    else searchParams.delete(name)

    setSearchParams(searchParams)
  }

  const checkAgeDriver = () =>{
    var date = form.getFieldValue("birthDate")
    if (date) {
      const birthday = new Date(dayjs(date).format("YYYY-MM-DD"))
      const today = new Date()
      const age = today.getFullYear() - birthday.getFullYear()

      if (age < 18){
        setIsAgeValid(false)
      }
      else{
        setIsAgeValid(true)
      }

      setIsAgeChosen(true)
    }
  }

  const disabledDate: AntDatePickerProps["disabledDate"] = (current) => {
    return current && current > dayjs().subtract(18, "year").endOf("day")
  }

  

  const onOpenHandler = (id?: number, isCustomerEdit?: boolean) => {
    if (id) {
      setId(id)
    }

    setIsCustomerEdit(isCustomerEdit ?? false)
    form.resetFields()
    setIsOpen(true)
    setOrDeleteQueryParams("driverAddForm", "true")
  }
  const tryDriver = async (data: DriverAddFormValues | undefined) => {
    try {
      if (data === undefined) {
        return
      }

      const request: IDriverCreate = {
        lastName: data.lastName,
        firstName: data.firstName,
        patronymic: data.patronymic,
        fullName: data.fullName,
        isCitizenRussian: data.isCitizenRussian === 0,
        typeIdentityDocument: data.typeIdentityDocument,
        birthDate: data.birthDate,
        birthPlace: data.birthPlace,
        passportSeries: data.passportSeries?.toString(),
        passportNumber: data.passportNumber?.toString(),
        passportIssuedBy: data.passportIssuedBy,
        passportDate: data.passportDate,
        passportDivisionCode: data.passportDivisionCode,
        region: data.region,
        district: data.district,
        settlement: data.settlement,
        street: data.street,
        house: data.house,
        building: data.building,
        apartment: data.apartment,
        phone: data.phone?.toString(),
        approved: true,
        clientIds: customerContext.isCustomer()
            ? customerContext.customer?.customerClientId
              ? [customerContext.customer?.customerClientId]
              : []
            : data.clientIds
      }

      const passportScans = fileList.map((file) => ({
        content: (file.url as string).split("base64,")[1],
        name: file.name
      }))

      setIsProcessing(true)

      if (id) {
        if (isCustomerEdit) {
          await uploadPassportScansMutation.mutateAsync({
            passportScans,
            driverId: Number(id)
          })
        } else {
          request.passportScans = passportScans
          await updateMutation.mutateAsync({
            ...request,
            id: id
          })
        }
      } else {
        await createMutation.mutateAsync(request).then(response => {
          if (response.succeeded)
            uploadPassportScansMutation.mutateAsync({
              passportScans,
              driverId: Number(response.response)
            })
        })
      }

      form.resetFields()
      onCloseHandler()
    } finally {
      setIsProcessing(false)
    }
  }
  const onFinish = (data: DriverAddFormValues) => {
    setData(data)
    setModalTitle("Предупреждение")
    setModalText(`Просим Вас проверить данные согласно паспорту, в случае если данные указаны не корректно или не соответствуют данным в паспорте –
    в записи на нефтебазу будет отказано!`)
    setIsModalOpen(true)
  }

  const handleOk = async () => {
    setIsModalOpen(false)
    await tryDriver(data)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const onFileListChange = (file: UploadFile | UploadFile[]) => {
    if (Array.isArray(file)) {
      setFileList(file)
      form.setFieldValue("passportScan", file.length)
    } else {
      setFileList((prevState) => [...prevState, file])
      form.setFieldValue("passportScan", fileList.length)
    }
  }

  const onIdentityDocumentChange = (identityDocument: number) => {
    if (IDriverTypeIdentityDocument.RussianPassport === identityDocument) {
      setHasPassportDivisionCode(true)
    } else {
      form.setFieldValue("passportDivisionCode", undefined)
      setHasPassportDivisionCode(false)
    }
  }

  const onCloseHandler = () => {
    setId(0)
    setIsCustomerEdit(false)
    form.resetFields()
    setFileList([])
    setIsOpen(false)
    setHasPassportDivisionCode(false)
    setOrDeleteQueryParams("driverAddForm")
  }

  return (
    <Drawer
      title={title}
      width={820}
      onClose={onCloseHandler}
      open={isOpen}
      styles={{ body: { paddingBottom: 80 } }}
      extra={
        <Button type="primary" disabled={ isProcessing } onClick={form.submit}>
          {formDriverText}
        </Button>
      }
    >
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Text>{modalText}</Text>
      </Modal>
      <Skeleton loading={id === 0 ? false : isLoading}>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          {
            !isCustomerEdit &&
            <>
                <Form.Item
                    hidden={customerContext.isCustomer()}
                    name="clientIds"
                    label="Клиент"
                    rules={[
                      {
                        required: !customerContext.isCustomer(),
                        message: "Пожалуйста выберите клиента"
                      }
                    ]}
                >
                    <Select
                        mode="multiple"
                        placeholder="Клиент"
                        showSearch
                        options={clients}
                    />
                </Form.Item>
                <Form.Item
                    label="Фамилия"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Пожалуйста введите Фамилия"
                      }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Имя"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Пожалуйста введите Имя"
                      }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Отчество"
                    name="patronymic"
                    rules={[
                      {
                        required: true,
                        message: "Пожалуйста введите Отчество"
                      }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="isCitizenRussian"
                    label="Является гражданином РФ"
                    rules={[
                      {
                        required: true,
                        message:
                          "Пожалуйста выберите является ли водитель гражданином РФ"
                      }
                    ]}
                >
                    <Select
                        placeholder="Является гражданином РФ"
                        showSearch
                        options={DriverCitizenRussianFederationForSelect.map((type) => ({
                          value: type.id,
                          label: type.value
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    name="typeIdentityDocument"
                    label="Тип документа, удостоверяющего личность"
                    rules={[
                      {
                        required: true,
                        message:
                          "Пожалуйста выберите тип документа, удостоверяющего личность"
                      }
                    ]}
                >
                    <Select
                        onChange={onIdentityDocumentChange}
                        placeholder="Тип документа, удостоверяющего личность"
                        showSearch
                        options={DriverTypeIdentityDocumentForSelect.map((type) => ({
                          value: type.id,
                          label: type.value
                        }))}
                    />
                </Form.Item>
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Дата рождения"
                            name="birthDate"
                            rules={[
                              {
                                required: true,
                                message: "Пожалуйста введите дату рождения"
                              }
                            ]}
                        >
                            <DatePicker
                                defaultPickerValue={birthdate ? dayjs(birthdate) : undefined}
                                onChange={checkAgeDriver}
                                disabledDate={disabledDate}
                                placeholder="Дата рождения"
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                      {( isAgeChosen && !isAgeValid) && <Alert message="Водитель должен быть старше 18 лет" type="warning" showIcon />}
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="birthPlace"
                            label="Место рождения"
                            rules={[
                              {
                                required: true,
                                message: "Пожалуйста введите место рождения"
                              }
                            ]}
                        >
                            <Input placeholder="Место рождения" />
                        </Form.Item>
                    </Col>
                </Row>
                <Collapse
                    className={classes["collapse-driver"]}
                    defaultActiveKey={["1"]}
                    ghost
                    items={[
                      {
                        key: "1",
                        label: "Паспорт гражданина РФ",
                        children: (
                          <>
                            <Row gutter={16}>
                              <Col xs={24} md={12}>
                                <Form.Item
                                  name="passportSeries"
                                  label="Серия"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Пожалуйста введите серию паспорта"
                                    }
                                  ]}
                                >
                                  <InputNumber
                                    style={{ width: "100%" }}
                                    placeholder="Серия"
                                    min={0}
                                    maxLength={4}
                                    step={1}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={12}>
                                <Form.Item
                                  name="passportNumber"
                                  label="Номер"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Пожалуйста введите номер паспорта"
                                    }
                                  ]}
                                >
                                  <InputNumber
                                    style={{ width: "100%" }}
                                    placeholder="Номер"
                                    min={0}
                                    maxLength={7}
                                    step={1}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={12}>
                                <Form.Item
                                  hidden={!hasPassportDivisionCode}
                                  name="passportDivisionCode"
                                  label="Код подразделения"
                                  normalize={(value) => value.toString()}
                                  rules={
                                    !hasPassportDivisionCode
                                      ? []
                                      : [
                                        {
                                          required: true,
                                          message:
                                            "Пожалуйста введите код подразделения"
                                        },
                                        {
                                          pattern: /^\d{3}-\d{3}$/,
                                          message: "Неверный формат, код должен быть вида: NNN-NNN"
                                        }
                                      ]
                                  }
                                >
                                  <Input
                                    style={{ width: "100%" }}
                                    placeholder="Код подразделения"
                                    min={0}
                                    maxLength={7}
                                    step={1}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} md={12}>
                                <Form.Item
                                  name="passportIssuedBy"
                                  label="Выдан"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Пожалуйста введите кем выдан паспорт"
                                    }
                                  ]}
                                >
                                  <Input placeholder="Выдан" />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={12}>
                                <Form.Item
                                  name="passportDate"
                                  label="Дата выдачи"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Пожалуйста выберите дату выдачи"
                                    }
                                  ]}
                                >
                                  <DatePicker
                                    style={{ width: "100%" }}
                                    placeholder="Дата выдачи"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        )
                      }
                    ]}
                ></Collapse>
                <Collapse
                    className={classes["collapse-driver"]}
                    defaultActiveKey={["1"]}
                    ghost
                    items={[
                      {
                        key: "1",
                        label: "Место жительства по прописке",
                        children: (
                          <>
                            <Row gutter={16}>
                              <Col xs={24} md={12}>
                                <Form.Item name="region" label="Регион"
                                rules={[
                                  {
                                    required: true,
                                    message: "Пожалуйста введите регион"
                                  }
                                  ]}>
                                  <Input placeholder="Регион" />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={12}>
                                <Form.Item
                                 name="district"
                                  label="Район"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Пожалуйста введите район"
                                    }
                                    ]}>
                                  <Input placeholder="Район" />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} md={12}>
                                <Form.Item name="settlement" label="Населенный пункт"
                                rules={[
                                  {
                                    required: true,
                                    message: "Пожалуйста введите населенный пункт"
                                  }
                                  ]}>
                                  <Input placeholder="Населенный пункт" />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={12}>
                                <Form.Item name="street" label="Улица"
                                rules={[
                                  {
                                    required: true,
                                    message: "Пожалуйста введите улицу"
                                  }
                                  ]}>
                                  <Input placeholder="Улица" />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} md={12}>
                                <Form.Item name="house" label="Дом"
                                rules={[
                                  {
                                    required: true,
                                    message: "Пожалуйста введите номер дома"
                                  }
                                  ]}>
                                  <Input placeholder="Дом" />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={12}>
                                <Form.Item name="building" label="Корпус">
                                  <Input placeholder="Корпус" />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} md={12}>
                                <Form.Item name="apartment" label="Квартира"
                                rules={[
                                  {
                                    required: true,
                                    message: "Пожалуйста введите номер квартиры"
                                  }
                                  ]}>
                                  <Input placeholder="Квартира" />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={12}>
                                <Form.Item
                                  name="phone"
                                  label="Номер телефона"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Пожалуйста введите номер телефона"
                                    }
                                  ]}
                                >
                                  <NumericInput
                                    type="number"
                                    prefix="+7"
                                    style={{ width: "100%" }}
                                    min={0}
                                    step={1}
                                    maxLength={PHONE_LENGTH}
                                    placeholder="Номер телефона"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        )
                      }
                    ]}
                ></Collapse>
            </>
          }
          <Form.Item
            name="passportScan"
            label="Скан паспорта с пропиской"
            rules={[
              {
                required: true,
                message: "Пожалуйста загрузите скан паспорта с пропиской"
              }
            ]}
          >
            <PicturesUpload
              onChangeHandler={onFileListChange}
              fileList={fileList}
            />
          </Form.Item>
        </Form>
      </Skeleton>
    </Drawer>
  )
})
