import { FC } from "react"

import { Typography } from "antd"

import { Table } from "@/components/_Shared/Table"
import { Resource } from "@/constants/permission"
import { getFuelQuotesArchiveExcel } from "@/http/fuelQuote"

import { fuelArchiveColumns } from "./FuelArchiveColumns"
import { useFuelArchiveFilters } from "./FuelArchiveFilters"

import { useFuelQuoteArchiveQuery } from "@/hook/FuelQuotes/useFuelQuotesQuery"
import { useDownloadFile } from "@/hook/useDownloadFile"
import { useTable } from "@/hook/useTable"

import { FUEL_QUOTES_ARCHIVE_KEY } from "@/types/IFuelQuote"

import { getInitialColumns } from "@/utils/getInitialColumns"

const { Text } = Typography

export const FuelArchivePage: FC = () => {

  const { filters } = useFuelArchiveFilters()

  const { download: excelDownload, isLoading: isExcelLoading } = useDownloadFile(() => getFuelQuotesArchiveExcel({
    Filter: filter.filter,
    OrderBy: order.orderBy
  }, getInitialColumns(fuelArchiveColumns, FUEL_QUOTES_ARCHIVE_KEY)))

  const { columns, pagination, filter, order } = useTable({
    initialColumns: getInitialColumns(fuelArchiveColumns, FUEL_QUOTES_ARCHIVE_KEY),
    key: FUEL_QUOTES_ARCHIVE_KEY
  })

  const { data, isLoading } = useFuelQuoteArchiveQuery({
    Page: pagination.page,
    PageSize: pagination.pageSize,
    Filter: filter.filter,
    OrderBy: order.orderBy
  })

  const onExcelDownloadHandler = async () => {
    await excelDownload()
  }

  return (
    <>
      <Table
        tableTitle="Архив цен на топливо"
        dataSource={data?.response.data}
        columns={columns.data}
        onUpdateColumns={columns.onColumnChange}
        rowKey="id"
        isLoading={isLoading}
        isSelectable={false}
        pagination={{
          currentPage: pagination.page,
          pageSize: pagination.pageSize,
          total: data?.response.count,
          onPageChange: pagination.onPageChange,
          onPageSizeChange: pagination.onPageSizeChange
        }}
        filters={{
          filters: filters,
          onSubmit: filter.onFilterChange,
          onCancel: filter.onFilterReset,
          filterKey: FUEL_QUOTES_ARCHIVE_KEY
        }}
        download={{
          onDownload: onExcelDownloadHandler,
          isLoading: isExcelLoading
        }}
        onSorterChange={order.setOrderBy}
        permission={{
            resource: Resource.Fuel
        }}
        expandable={{
          expandedRowRender: (fuelQuote) => (
            <Text>{fuelQuote.commentary}</Text>
          ),
          rowExpandable: (fuelQuote) =>
            (fuelQuote.commentary != undefined &&
            fuelQuote.commentary != ""),
          expandRowByClick: true
        }}
      />
    </>
  )
}
