import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react"

import { Button, Col, Drawer, Form, Input, InputNumber, Row, Select } from "antd"
import dayjs, { Dayjs } from "dayjs"

import { CustomRangePicker } from "@/components/_Shared/CustomRangePicker"

import { useFuelsSelectListQuery } from "@/hook/Dictionaries/useFuelQuery"
import { useFuelLimitsMutation } from "@/hook/FuelLimits/useFuelLimitsMutation"
import { useFuelLimitByIdQuery } from "@/hook/FuelLimits/useFuelLimitsQuery"

import { IFuelLimitCreate } from "@/types/IFuelLimit"

import { formatDate } from "@/utils/formatDate"

const { TextArea } = Input

export interface FuelLimitAddFormValues {
  startDate: Date,
  endDate: Date,
  limit: number,
  commentary: string,
  fuelId: number

  rangeDates: string
}

export interface FuelLimitAddFormProps {
  open(fuelLimitId?: number): void

  close(): void
}

export const FuelLimitAddForm = forwardRef<FuelLimitAddFormProps, {handleClose?: Function}>((props, ref) => {

  const [form] = Form.useForm<FuelLimitAddFormValues>()
  const [id, setId] = useState<number>(0)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState(false)

  const { data: fuelLimit } = useFuelLimitByIdQuery(id)
  const { data: fuels } = useFuelsSelectListQuery()

  const { createMutation, updateMutation } = useFuelLimitsMutation()

  useImperativeHandle(ref, () => ({
    open(fuelLimitId?: number) {
      onOpenHandler(fuelLimitId)
    },
    close() {
      onCloseHandler()
    }
  }))

  useEffect(() => {
    if (fuelLimit && isOpen) {
      const response = fuelLimit.response
      console.log(response)

      form.setFields([
        {
          name: "fuelId",
          value: response.fuelId
        },
        {
          name: "startDate",
          value: response.startDate
        },
        {
          name: "endDate",
          value: response.endDate
        },
        {
          name: "limit",
          value: response.actualLimit
        },
        {
          name: "commentary",
          value: response.commentary
        }
      ])

      form.setFieldValue("rangeDates", [dayjs(response.startDate), dayjs(response.endDate)])
    }
  }, [id, fuelLimit, form, isOpen])

  const title = useMemo(() => {
    if (id) {
      return "Редактировать"
    }

    return "Добавить"
  }, [id])


  const onOpenHandler = (fuelLimitId?: number) => {
    form.resetFields()
    setIsOpen(true)

    if (fuelLimitId) {
      setId(fuelLimitId)
    }
  }

  const onCloseHandler = () => {
    setId(0)
    form.resetFields()
    setIsOpen(false)
    if(props.handleClose) {
      props.handleClose()
    }
  }

  const onDataPickerChanges = () => {
    const range = form.getFieldValue("rangeDates") as Dayjs[]
    form.setFieldValue("startDate", range[0].toDate())
    form.setFieldValue("endDate", range[1].toDate())
  }

  const onFinish = async (data: FuelLimitAddFormValues) => {
    try {
      const request: IFuelLimitCreate = {
        limit: data.limit,
        startDate: formatDate(data.startDate, "YYYY-MM-DD"),
        endDate: formatDate(data.endDate, "YYYY-MM-DD"),
        fuelId: data.fuelId,
        commentary: data.commentary
      }

      setIsProcessing(true)

      if (id) {
        await updateMutation.mutateAsync({
          ...request,
          id: id
        })
      } else {
        await createMutation.mutateAsync(request)
      }

      form.resetFields()
      onCloseHandler()
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <Drawer
      title={title}
      width={820}
      onClose={onCloseHandler}
      open={isOpen}
      styles={{ body: { paddingBottom: 80 } }}
      extra={
        <Button type="primary" disabled={isProcessing} onClick={form.submit}>
          Сохранить
        </Button>
      }
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item hidden={true} name="startDate" />
        <Form.Item hidden={true} name="endDate" />
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="fuelId"
              label="Топливо"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста выберите топливо"
                }
              ]}
            >
              <Select
                placeholder="Топливо"
                showSearch
                options={fuels?.response}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item name="rangeDates" label="Период"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, заполните период"
                }
              ]}
            >
              <CustomRangePicker style={{ width: "100%" }} onChange={onDataPickerChanges}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
        <Col xs={12} md={12}>
          <Form.Item
              name="limit"
              label="Лимит"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, заполните лимит"
                }
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Лимит"
                min={0}
                step={1}
              />
            </Form.Item>
          </Col>
          </Row>
          <Row gutter={16}>
          <Col xs={24} md={12}>
          <Form.Item
              name="commentary"
              label="Комментарий"
            >
              <TextArea
                placeholder="Комментарий"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  )
})
