import { ICustomerClient } from "./ICustomerClient"

import { ISelectListItem } from "@/types/ui"

export interface IDocumentFile {
	content: string
	name: string
  }

export interface ITransport {
	id: number
	model: string
	modelId: number
	type: string
	typeId: number
	class: ITransportClass
	className: string
	number: string
	region?: string
	brand?: string
	color?: string
	vin?:  string
	owner?: string
	weightWithoutLoad?: number
	permittedMaximumMass?: number
	axlesNumber?: number
	liter?: number
	certificateType?: ITransportCertificateType
	certificateNumber?: string
	dateExpirationCertificate?: string
	dateBeginningCertificate?: string
	section1?: number
	section2?: number
	section3?: number
	section4?: number
	section5?: number
	section6?: number
	section7?: number
	section8?: number
	approved?: boolean
	verificationStatus: number
	verificationStatusText: string
	clients: ICustomerClient[]
	stsScans?: IDocumentFile[]
}

export enum VerificationStatus
{
    Awaits,
    Rejected,
    Confirmed,
		AwaitsForScans
}

export enum ITransportClass {
  Truck,
  Pickup,
	TankerTruck
}

export interface ITransportType {
  id: number
  name: string
  class: ITransportClass
  className: string
}

export enum ITransportCertificateType {
	Calibration,
	Verification
}

export const TransportClassForSelect = [
  { id: ITransportClass.Truck, value: "Тягач" },
  { id: ITransportClass.Pickup, value: "Прицеп" },
  { id: ITransportClass.TankerTruck, value: "Автоцистерна" }
]

export const TransportCertificateTypes: ISelectListItem[] = [
	{
		value: ITransportCertificateType.Calibration,
		label: "Сертификат о калибровке"
	},
	{
		value: ITransportCertificateType.Verification,
		label: "Свидетельство о поверке"
	}
]

// При изменении соблюдать порядок с ITransportCertificateType
export const TransportCertificateTypesGenitives = [
	"сертификата о калибровке",
	"свидетельства о поверке"
]

export interface ITransportCreate {
	modelId: number
	typeId: number
	modelName: string
	typeName: string
	class: ITransportClass
	number: string
	region: string
	brand: string
	color: string
	vin:  string
	owner: string
	weightWithoutLoad: number
	permittedMaximumMass: number
	axlesNumber: number
	liter: number
	certificateType?: ITransportCertificateType
	certificateNumber?: string
	dateExpirationCertificate?: string
	dateBeginningCertificate?: string
	section1: number
	section2: number
	section3: number
	section4: number
	section5: number
	section6: number
	section7: number
	section8: number
    approved: boolean
    clientIds: number[]
	stsScans?: IDocumentFile[]
}

export interface ITransportUpdate extends ITransportCreate {
  id: number
}

export interface ITransportChangeVerificationStatus {
	id : number
	status : VerificationStatus
  }

export interface IUploadTransportSTSScansRequest {
	stsScans?: IDocumentFile[]
	transportId: number
}

export const TRANSPORT_KEY = "TRANSPORT_KEY"
