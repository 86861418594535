import { useQuery } from "@tanstack/react-query"

import { NOTIFICATION_QUERY_KEY,
        NOTIFICATION_NOT_READ_QUERY_KEY,
        NOTIFICATION_NOT_READ_COUNT_QUERY_KEY,
        NOTIFICATION_COUNT_BY_VERIFICATION_AWAITS_QUERY_KEY } from "@/constants/query"
import { 
    getNotificaitonsByCurrentUser,
    getNotReadNotificaitonsByCurrentUser,
    getNotReadCountNotificaitonsByCurrentUser, 
    getCountByAwaitsVerificationStatus
} from "@/http/notificaiton"

import { IRequestParams } from "@/types/api"

export const useNotification = (params?: IRequestParams) => {
    return useQuery({
        queryFn: () => getNotificaitonsByCurrentUser(params ?? {}),
        queryKey: [NOTIFICATION_QUERY_KEY],
        retry: false
    })
}

export const useNotReadNotification = (countRecord?: number) => {
    return useQuery({
        queryFn: () => getNotReadNotificaitonsByCurrentUser(countRecord),
        queryKey: [NOTIFICATION_NOT_READ_QUERY_KEY],
        retry: false
    })
}

export const useNotReadCountNotification = () => {
    return useQuery({
        queryFn: () => getNotReadCountNotificaitonsByCurrentUser(),
        queryKey: [NOTIFICATION_NOT_READ_COUNT_QUERY_KEY],
        retry: false
    })
}

export const useCountByVerificationAwaitsQuery = (enable: boolean) => {
    return useQuery({
        queryFn: () => getCountByAwaitsVerificationStatus(),
        queryKey: [NOTIFICATION_COUNT_BY_VERIFICATION_AWAITS_QUERY_KEY],
        retry: false,
        enabled: enable
    })
}
