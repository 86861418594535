import { FC, Key, useContext, useRef } from "react"

import { useNavigate, useSearchParams } from "react-router-dom"

import { Table } from "@/components/_Shared/Table"
import {
  TransportAddForm,
  TransportAddFormProps
} from "@/components/Transport/TransportAddForm"
import { Resource } from "@/constants/permission"
import { CustomerContext } from "@/context/CustomerContext"
import { getTransportExcel } from "@/http/transport"
import { transportColumns, additionalTransportColumns } from "@/pages/Transport/TransportsColumns"

import { useTransportsFilters } from "./TransportsFilters"

import { useTransportQuery } from "@/hook/Dictionaries/useTransportQuery"
import { useTransportsMutation } from "@/hook/Dictionaries/useTransportsMutation"
import { useDownloadFile } from "@/hook/useDownloadFile"
import { useLocationSearchHandler } from "@/hook/useLocationSearchHandler"
import { useTable } from "@/hook/useTable"

import { TRANSPORT_KEY, ITransport, VerificationStatus } from "@/types/ITransport"

import { paths } from "@/router/paths"

import { getInitialColumns } from "@/utils/getInitialColumns"

const TransportsPage: FC = () => {
  const customerContext = useContext(CustomerContext)

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const transportComponent = useRef<TransportAddFormProps>(null)

  const { download: excelDownload, isLoading: isExcelLoading } =
    useDownloadFile(() =>
      getTransportExcel(
        {
          Filter: filter.filter,
          OrderBy: order.orderBy
        },
        getInitialColumns(transportColumns, TRANSPORT_KEY)
      )
    )

  const { deleteMutation, checkMutation } = useTransportsMutation()

  const { columns, pagination, filter, order } = useTable({
    initialColumns: getInitialColumns([...transportColumns, ...(customerContext.isCustomer() ? [] : additionalTransportColumns)], TRANSPORT_KEY),
    key: TRANSPORT_KEY
  })

  const { filters } = useTransportsFilters()

  const { data, isLoading } = useTransportQuery(
    {
      Page: pagination.page,
      PageSize: pagination.pageSize,
      Filter: filter.filter,
      OrderBy: order.orderBy
    },
    customerContext.customer?.id
  )

  const onRowClickHandler = (data: ITransport, index: number | undefined) => {
    navigate(paths.transportById(data.id))
  }

  const onAddHandler = () => {
    transportComponent.current?.open()
  }

  const onEditHandler = (id: number) => {
    transportComponent.current?.open(id, customerContext.isCustomer())
  }

  const onRemoveHandler = async (ids: Key[]) => {
    await deleteMutation.mutateAsync(ids.map((id) => Number(id)))
  }

  const onActionHandler = async (ids: Key[]) => {
    await checkMutation.mutateAsync(ids.map((id) => Number(id)))
  }

  const onOpenForEdit = () => {
    const searchId = searchParams.get("edit")
    if (searchId) {
      onEditHandler(Number(searchId))
    }
  }

  const onExcelDownloadHandler = async () => {
    await excelDownload()
  }

  useLocationSearchHandler({
    value: "edit",
    handler: onOpenForEdit
  })

  useLocationSearchHandler({
    value: "openForm",
    handler: onAddHandler
  })

  return (
    <>
      <TransportAddForm ref={transportComponent}/>
      <Table
        tableTitle="Транспорт"
        dataSource={data?.response.data}
        columns={columns.data}
        rowKey="id"
        isLoading={isLoading}
        pagination={{
          currentPage: pagination.page,
          pageSize: pagination.pageSize,
          total: data?.response.count,
          onPageChange: pagination.onPageChange,
          onPageSizeChange: pagination.onPageSizeChange
        }}
        onAdd={onAddHandler}
        onEdit={onEditHandler}
        onRemove={onRemoveHandler}
        actionText="Проверить в реестре"
        onAction={onActionHandler}
        addText="Добавить"
        onRowClick={onRowClickHandler}
        onSorterChange={order.setOrderBy}
        filters={{
          filters: filters,
          onSubmit: filter.onFilterChange,
          onCancel: filter.onFilterReset,
          filterKey: TRANSPORT_KEY
        }}
        download={{
          onDownload: onExcelDownloadHandler,
          isLoading: isExcelLoading
        }}
        permission={{
          resource: Resource.Transport
        }}
      />
    </>
  )
}

export default TransportsPage
