export interface INotification {
    id: number
    date: Date
    isRead: boolean
    message:string
    notificationType: NotificationType
}

export interface IUpdateReadStatusNotifications{
    ids: number[]
    isRead: boolean
}

export interface IInformationNotification{
    message:string 
}

export interface ICountEntityVerificationCatalog{
    countTranspoert:number
    countDriver:number
    countDeliveryAddress:number
}

export enum NotificationType{
    changeOrderStatus,
    informationAllUsers,
    updateCatalogData,
    timeUnloadFuelSlotRelease
}