import { useMutation, useQueryClient } from "@tanstack/react-query"

import { TRANSPORT_QUERY_KEY } from "@/constants/query"
import {
  checkTransports,
  createTransport,
  deleteTransports,
  updateTransport,
  changeVerificationTransport,
  uploadTransportSTSScans
} from "@/http/transport"

import { useMessage } from "@/hook/useMessage"

export const useTransportsMutation = () => {
  const client = useQueryClient()
  const { Success, Error, Process } = useMessage(TRANSPORT_QUERY_KEY)

  const onSuccess = () => {
    client.invalidateQueries([TRANSPORT_QUERY_KEY]).then()
    Success()
  }

  const onMutate = () => {
    Process()
  }

  const onError = (e: any) => {
    Error(e)
  }

  const createMutation = useMutation({
    mutationFn: createTransport,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const updateMutation = useMutation({
    mutationFn: updateTransport,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const deleteMutation = useMutation({
    mutationFn: deleteTransports,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const checkMutation = useMutation({
    mutationFn: checkTransports,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const changeVerificationMutation = useMutation({
    mutationFn: changeVerificationTransport,
    onSuccess: () => onSuccess(),
    onMutate : () => onMutate(),
    onError : (e) => onError(e)
  })

  const uploadSTSScansMutation = useMutation({
    mutationFn: uploadTransportSTSScans,
    onSuccess: () => onSuccess(),
    onMutate : () => onMutate(),
    onError: (e) => onError(e)
  })

  return {
    createMutation,
    updateMutation,
    deleteMutation,
    checkMutation,
    changeVerificationMutation,
    uploadSTSScansMutation
  }
}
