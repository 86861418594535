import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IDepotFuel } from "@/types/IDepot"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import {
  IFuelRecord,
  IFuelOrder,
  IFuel,
  IFuelCreate,
  IFuelUpdate,
  IFuelList,
  IFuelByDateRecord
} from "@/types/IFuel"
import { ISelectListItem, ITableColumn } from "@/types/ui"

export interface IFuelForOrderParam {
  Date: Date
  DepotId: number
  CustomerId: number
  FuelTypeId : number
}

export interface IFuelsByDepotParam {
  DepotId: number
  Date: Date
  CustomerId: number | undefined
}

export interface IFuelRecordsByDepotParam {
  DepotId: number
  CustomerClientId: number
}

export interface IFuelRecordsByDateParam {
  Date: Date | string
  CustomerClientId: number
  DepotIds?: number[]
  FuelTypeIds?: number[]
}

export interface IFuelRecordsByDepotForManagerParam {
  DepotId?: number
}

export const getFuel = (
  params: IRequestParams
): Promise<IPagingResponse<IFuelList>> =>
  client.get("/Fuels", {
    params
  })

export const getFuelById = (id: number): Promise<IResponse<IFuel>> =>
  client.get(`/Fuels/${id}`)

export const getFuelForOrder = (
  params: IFuelForOrderParam
): Promise<IResponse<IFuelOrder>> =>
  client.get("/Fuels/for-order", {
    params
  })

export const getFuelsForOrderByDepot = (params: IFuelsByDepotParam): Promise<IResponse<IDepotFuel[]>> =>
  client.get("/Fuels/by-depot", {
    params
})

export const getFuelsSelectList =  (): Promise<IResponse<ISelectListItem[]>> => client.get("/Fuels/selectList")

export const getFuelRecordsByDepotForCustomer = (
  params: IFuelRecordsByDepotParam
): Promise<IResponse<IFuelRecord[]>> =>
  client.get("/Fuels/customer/by-depot", {
    params
  })

export const getFuelRecordsByDepotForManager = (
  params: IFuelRecordsByDepotForManagerParam
): Promise<IResponse<IFuelRecord[]>> =>
  client.get("/Fuels/manager/by-depot", {
    params
  })

export const getFuelRecordsByDateForCustomer = (
  params: IFuelRecordsByDateParam
): Promise<IResponse<IFuelByDateRecord[]>> =>
  client.post("/Fuels/customer/by-date", params)

export const createFuel = (body: IFuelCreate): Promise<IResponse<number>> =>
  client.post("/Fuels", body)

export const updateFuel = (body: IFuelUpdate): Promise<IResponse<string>> =>
  client.put("/Fuels", body)

export const getFuelsExcel = (
  params: IRequestParams,
  columns?: ITableColumn<IFuelList>[]
): Promise<IResponse<IDownloadDocument>> =>
  client.post("/Fuels/Excel", columns, {
    params
  })

export const deleteFuels = (ids: number[]): Promise<IResponse<boolean>> =>
  client.delete("/Fuels", {
    data: {
      ids
    }
  })
