import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import { VerificationStatus } from "@/types/IDriver"
import {
  ITransport,
  ITransportCreate,
  ITransportUpdate,
  ITransportChangeVerificationStatus, IUploadTransportSTSScansRequest
} from "@/types/ITransport"
import { ITableColumn } from "@/types/ui"

export const getTransport = (
  params: IRequestParams,
  customerId?: number
): Promise<IPagingResponse<ITransport>> =>
  client.get(`/Transports${customerId ? "/" + customerId : ""}`, {
    params
  })

export const getTransportForOrder = (
  orderId: number,
  customerId?: number
): Promise<IResponse<ITransport[]>> =>
  client.get(
    `/Transports/Order/${orderId}${customerId ? "/" + customerId : ""}`
  )

export const getTransportById = (id: number): Promise<IResponse<ITransport>> =>
  client.get(`/Transports/ById/${id}`)

export const createTransport = (
  body: ITransportCreate
): Promise<IResponse<number>> => client.post("/Transports", body)

export const updateTransport = (
  body: ITransportUpdate
): Promise<IResponse<string>> => client.put("/Transports", body)

export const getTransportExcel = (
  params: IRequestParams,
  columns?: ITableColumn<ITransport>[]
): Promise<IResponse<IDownloadDocument>> =>
  client.post("/Transports/Excel", columns, {
    params
  })
export const deleteTransports = (ids: number[]): Promise<IResponse<boolean>> =>
  client.delete("/Transports", {
    data: {
      ids
    }
  })

export const checkTransports = (ids: number[]): Promise<IResponse<boolean>> =>
  client.post("/Transports/Check", { ids })

export const changeVerificationTransport = (body: ITransportChangeVerificationStatus): Promise<IResponse<string>> =>
  client.put("/Transports/verificaton-transport", body)

export const uploadTransportSTSScans = (body: IUploadTransportSTSScansRequest): Promise<IResponse<never>> =>
  client.post("/Transports/sts", body)
