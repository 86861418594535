import React, { createContext, useContext, useState, useEffect, FC } from "react"

import { Resource, Scope } from "@/constants/permission"

import { useCountByVerificationAwaitsQuery, useNotReadCountNotification } from "@/hook/Notifications/useNotificaitonQuery"
import { usePermission } from "@/hook/usePermission"

import { ICountEntityVerificationCatalog } from "@/types/INotificaiton"




interface NotificationContextProps {
  countNotReadNotifications: number;
  countAwaitsVerification: ICountEntityVerificationCatalog;
  refetchNotifications: () => void;
  refetchCountAwaitsVerification: () => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined)

export const useNotificationContext = () => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error("Контекст уведомлений не доступен")
  }
  return context
}

export const NotificationProvider: FC<any> = ({ children }) => {
  const [countNotReadNotifications, setCountNotReadNotifications] = useState<number>(0)
  const [countAwaitsVerification, setCountNotVerification] = useState<ICountEntityVerificationCatalog>({
    countDriver:0,
    countDeliveryAddress:0,
    countTranspoert:0
  })

  const { hasPermission } = usePermission()
  const { data: countAwaitsVerificationData, refetch: refetchCountAwaitsVerificationData } =
   useCountByVerificationAwaitsQuery(hasPermission(Resource.Notification, Scope.Actions))

  const { data, refetch } = useNotReadCountNotification()

  useEffect(() => {
    setCountNotReadNotifications(data?.response ?? 0)
    if (countAwaitsVerificationData?.response) {
      setCountNotVerification(countAwaitsVerificationData?.response)
    }
  }, [data, countAwaitsVerificationData])

  const refetchNotifications = () => {
    refetch() 
  }
  const refetchCountAwaitsVerification = () =>{
    refetchCountAwaitsVerificationData()
  }

  return (
    <NotificationContext.Provider value={{ 
      countNotReadNotifications,
       countAwaitsVerification,
        refetchNotifications,
        refetchCountAwaitsVerification }}>
      {children}
    </NotificationContext.Provider>
  )
}
