import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react"

import { Button, Checkbox, Col, DatePicker, Drawer, Form, Input, Row, Select, Tooltip } from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import dayjs, { Dayjs } from "dayjs"

import { NumericInput } from "@/components/_Shared/NumericInput"

import { useCustomerClientsSelectListQuery } from "@/hook/CustomerClients/useCustomerClientQuery"
import { useCustomerMutation } from "@/hook/Dictionaries/Customers/useCustomerMutation"
import { useCustomerByIdQuery } from "@/hook/Dictionaries/Customers/useCustomerQuery"
import { useCountriesSelectListQuery } from "@/hook/Dictionaries/useCountryQuery"

import { ICustomerCreate, ISupplyAgreement } from "@/types/ICustomer"

import { INDEX_LENGTH, INN_UL_LENGTH, KPP_LENGTH, OGRN_LENGTH, PHONE_LENGTH } from "@/utils/constants"
import { filterSelectOption } from "@/utils/filterSelectOption"

export interface CustomerAddFormProps {
  open(id?: number): void

  close(): void
}

interface SupplyAgreementValues {
  date: Dayjs | null
  number: string
}

interface CustomerAddFormValues {
  name: string
  balance: number
	dayLimit: number
	inn: string
	kpp: string
	ogrn: string
	postAddress: CustomerAddressFormValues
	legalAddress: CustomerAddressFormValues
	phone: string
  isAddressesMatches: boolean
  customerClientId: number
  supplyAgreement: SupplyAgreementValues
}

interface CustomerAddressFormValues {
  buildingNumber: number
	countryId: 1
	district: string
	city: string
	postCode: string
	region: string
	roomNumber: number
	settlement: string
	street: string
}

export const CustomerAddForm = forwardRef<CustomerAddFormProps>(
  (props, ref) => {
    const [form] = Form.useForm<CustomerAddFormValues>()
    const [id, setId] = useState<number>(0)
    const [isOpen, setIsOpen] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)

    const [isAddressesMatches, setIsAddressesMatches] = useState(false)
    const [legalAddressRequired, setLegalAddressRequired] = useState(false)
    const [postAddressRequired, setPostAddressRequired] = useState(false)

    const { data: countries } = useCountriesSelectListQuery()
    const { data: customerClients } = useCustomerClientsSelectListQuery()

    const { data: customer } = useCustomerByIdQuery(id)
    const { createMutation, updateMutation } = useCustomerMutation()

    const onAddressesMatchesCheckboxChange = (e: CheckboxChangeEvent) => {
      setIsAddressesMatches(e.target.checked)
      if (e.target.checked) {
        setIdenticalAddresses()
      }
    }
  
    const onLegalAddressChange = () => {
      if (isAddressesMatches) {
        setIdenticalAddresses()
      }
    }
  
    const setIdenticalAddresses = () => {
      const legalAddress = "legalAddress"
      const postAddress = "postAddress"
      form.setFieldValue([postAddress, "countryId"], form.getFieldValue([legalAddress, "countryId"]))
      form.setFieldValue([postAddress, "region"], form.getFieldValue([legalAddress, "region"]))
      form.setFieldValue([postAddress, "district"], form.getFieldValue([legalAddress, "district"]))
      form.setFieldValue([postAddress, "city"], form.getFieldValue([legalAddress, "city"]))
      form.setFieldValue([postAddress, "settlement"], form.getFieldValue([legalAddress, "settlement"]))
      form.setFieldValue([postAddress, "street"], form.getFieldValue([legalAddress, "street"]))
      form.setFieldValue([postAddress, "buildingNumber"], form.getFieldValue([legalAddress, "buildingNumber"]))
      form.setFieldValue([postAddress, "roomNumber"], form.getFieldValue([legalAddress, "roomNumber"]))
      form.setFieldValue([postAddress, "postCode"], form.getFieldValue([legalAddress, "postCode"]))
    }

    const onSubmitHandler = () => {
      const legalAddressCity = form.getFieldValue(["legalAddress", "city"])
      const legalAddressSettlement = form.getFieldValue(["legalAddress", "settlement"])
  
      const postAddressCity = form.getFieldValue(["postAddress", "city"])
      const postAddressSettlement = form.getFieldValue(["postAddress", "settlement"])
  
      setLegalAddressRequired(!legalAddressCity && !legalAddressSettlement)
      setPostAddressRequired(!postAddressCity && !postAddressSettlement)
  
      form.submit()
    }

    useImperativeHandle(ref, () => ({
      open(id?: number) {
        onOpenHandler(id)
      },
      close() {
        onCloseHandler()
      }
    }))

    useEffect(() => {
      if (customer && isOpen) {
        const response = customer.response

        const isChecked = JSON.stringify(response.postAddress) === JSON.stringify(response.legalAddress)
        setIsAddressesMatches(isChecked)

        const agreementValues: SupplyAgreementValues = 
          { 
            date: response.supplyAgreement?.date ? dayjs(response.supplyAgreement?.date) : null,
            number: response.supplyAgreement?.number 
          }

        form.setFields([
          {
            name: "name",
            value: response.name
          },
          {
            name: "dayLimit",
            value: response.dayLimit
          },
          {
            name: "customerClientId",
            value: response.customerClientId
          },
          {
            name: "balance",
            value: response.balance
          },
          {
            name: "phone",
            value: response.phone
          },
          {
            name: "inn",
            value: response.inn
          },
          {
            name: "kpp",
            value: response.kpp
          },
          {
            name: "ogrn",
            value: response.ogrn
          },
          {
            name: "postAddress",
            value: response.postAddress
          },
          {
            name: "legalAddress",
            value: response.legalAddress
          },
          {
            name: "isAddressesMatches",
            value: isChecked
          },
          {
            name: "supplyAgreement",
            value: agreementValues
          }
        ])
      } else {
        setIsAddressesMatches(false)
      }
    }, [id, customer, form, isOpen])

    const title = useMemo(() => {
      const customer = " контрагента"
      if (id) {
        return "Редактировать" + customer
      }

      return "Добавить" + customer
    }, [id])

    const onOpenHandler = (id?: number) => {
      if (id) {
        setId(id)
      }
      form.resetFields()
      setIsOpen(true)
    }

    const onFinish = async (data: CustomerAddFormValues) => {
      try {
        const request: ICustomerCreate = {
          name: data.name,
          balance: data.balance,
          dayLimit: data.dayLimit,
          inn: data.inn,
          kpp: data.kpp,
          ogrn: data.ogrn === "" ? undefined : data.ogrn,
          postAddress: data.postAddress,
          legalAddress: data.legalAddress,
          phone: data.phone,
          customerClientId: data.customerClientId,
          supplyAgreement: {
            date: data.supplyAgreement.date ? new Date(data.supplyAgreement.date.toISOString()) : null,
            number: data.supplyAgreement.number
          }
        }
        setIsProcessing(true)

        if (id) {
          await updateMutation.mutateAsync({
            ...request,
            id: id
          })
        } else {
          await createMutation.mutateAsync(request)
        }

        form.resetFields()
        onCloseHandler()
      } finally {
        setIsProcessing(false)
      }
    }

    const onCloseHandler = () => {
      setId(0)
      form.resetFields()
      setIsOpen(false)
    }

    return (
      <Drawer
        title={title}
        width={820}
        onClose={onCloseHandler}
        open={isOpen}
        styles={{ body: { paddingBottom: 80 } }}
        extra={
          <Button type="primary" disabled={isProcessing} onClick={onSubmitHandler}>
            Сохранить
          </Button>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                name="customerClientId"
                rules={[{ required: true, message: "Контрагент - обязательное поле" }]}
              >
                <Select
                  placeholder="Контрагент"
                  allowClear={false}
                  showSearch
                  options={customerClients?.response}
                  filterOption={(inputValue, option) => filterSelectOption(inputValue, option?.label || "")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name="name"
                label="Наименование"
                rules={[
                  { required: true, message: "Пожалуйста введите наименование" }
                ]}
              >
                <Input placeholder="Наименование" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Дневной лимит"
                name="dayLimit" initialValue={0}
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста введите дневной лимит"
                  }
                ]}
              >
                <NumericInput type="number" placeholder="Дневной лимит" defaultValue="0" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
            <Form.Item label="Номер телефона" name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Пожалуйста введите номер телефона"
                      },
                      { len: PHONE_LENGTH, message: `Номер телефона должен состоять из ${PHONE_LENGTH} цифр` }
                    ]}
              >
              <NumericInput prefix="+7" type="number" placeholder="Номер телефона" maxLength={PHONE_LENGTH}/>
            </Form.Item>
            </Col>
            <Col xs={24} md={12}>
            <Form.Item label="Баланс" name="balance" initialValue={0}
                        rules={[
                          {
                            required: true,
                            message: "Пожалуйста введите баланс"
                          }
                        ]}
              >
              <NumericInput placeholder="Баланс" defaultValue="0" />
            </Form.Item>
            </Col>
          </Row>
          <Form.Item
              label="ИНН"
              name="inn"
              rules={[
                { required: true, message: "Пожалуйста введите ИНН" },
                { len: INN_UL_LENGTH, message: `ИНН должен состоять из ${INN_UL_LENGTH} цифр` }
              ]}
          >
            <NumericInput maxLength={INN_UL_LENGTH}/>
          </Form.Item>
          <Form.Item
              label="КПП"
              name="kpp"
              rules={[
                { required: true, message: "Пожалуйста введите КПП" },
                { len: KPP_LENGTH, message: `КПП должен состоять из ${KPP_LENGTH} цифр` }
              ]}
          >
            <NumericInput maxLength={KPP_LENGTH}/>
          </Form.Item>
          <Form.Item
              label="ОГРН"
              name="ogrn"
              rules={[
                { len: OGRN_LENGTH, message: `ОГРН должен состоять из ${OGRN_LENGTH} цифр` }
              ]}
          >
            <NumericInput maxLength={OGRN_LENGTH}/>
          </Form.Item>
          <Form.Item
            label="Договор поставки"
            required
          >
            <Input.Group>
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name={["supplyAgreement", "date"]}
                    rules={[
                      { required: true, message: "Пожалуйста введите дату договора поставки" }
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} placeholder='Дата договора поставки' inputReadOnly={false} format="DD.MM.YYYY"/>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    initialValue={""}
                    name={["supplyAgreement", "number"]}
                    rules={[
                      { required: true, message: "Пожалуйста введите номер договора поставки" }
                    ]}
                  >
                    <Input placeholder='Номер договора поставки'/>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
          </Form.Item>
          {(customer?.response || !id) && (
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="isAddressesMatches" valuePropName="checked">
                    <Checkbox checked={isAddressesMatches} onChange={onAddressesMatchesCheckboxChange}>Почтовый адрес совпадает с юридическим</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item required label="Юридический адрес">
                    <Input.Group>
                      <Form.Item
                          name={["legalAddress", "countryId"]}
                          rules={[{ required: true, message: "Страна обязательное поле" }]}
                      >
                        <Select
                            placeholder="Страна"
                            allowClear={false}
                            showSearch
                            options={countries?.response}
                            filterOption={(inputValue, option) => filterSelectOption(inputValue, option?.label || "")}
                            onChange={onLegalAddressChange}
                        />
                      </Form.Item>

                      <Tooltip title="Республика, автономная область, край, город федерального назначения, специальная территория"
                               placement="topRight"
                               color="blue">
                        <Form.Item
                            name={["legalAddress", "region"]}
                            rules={[{ required: true, message: "Республика/автономная область/край/город федерального назначения/специальная территория обязательное поле" }]}
                        >
                            <Input placeholder="Республика, автономная область, край, город федерального назначения, специальная территория" onChange={onLegalAddressChange} />
                        </Form.Item>
                      </Tooltip>

                      <Form.Item
                          name={["legalAddress", "district"]}
                      >
                        <Input placeholder="Район" onChange={onLegalAddressChange} />
                      </Form.Item>

                      <Form.Item
                          name={["legalAddress", "city"]}
                          rules={[{ required: legalAddressRequired, message: "Город или населенный пункт обязательное поле" }]}
                      >
                        <Input placeholder="Город" onChange={onLegalAddressChange} />
                      </Form.Item>

                      <Form.Item
                          name={["legalAddress", "settlement"]}
                          rules={[{ required: legalAddressRequired, message: "Город или населенный пункт обязательное поле" }]}
                      >
                        <Input placeholder="Населенный пункт" onChange={onLegalAddressChange} />
                      </Form.Item>

                      <Form.Item
                          name={["legalAddress", "street"]}
                          rules={[{ required: true, message: "Улица обязательное поле" }]}
                      >
                        <Input placeholder="Улица" onChange={onLegalAddressChange} />
                      </Form.Item>

                      <Form.Item
                          name={["legalAddress", "buildingNumber"]}
                          rules={[{ required: true, message: "Номер дома/корпуса обязательное поле" }]}
                      >
                        <Input placeholder="Дом, корпус" onChange={onLegalAddressChange} />
                      </Form.Item>

                      <Form.Item
                          name={["legalAddress", "roomNumber"]}
                      >
                        <Input placeholder="Номер помещения" onChange={onLegalAddressChange} />
                      </Form.Item>

                      <Form.Item
                          name={["legalAddress", "postCode"]}
                          rules={[{ required: true, message: "Индекс обязательное поле" }, { len: INDEX_LENGTH, message: "Индекс должен состоять из 6 цифр" }]}
                      >
                        <NumericInput maxLength={INDEX_LENGTH} placeholder="Индекс" onChange={onLegalAddressChange} />
                      </Form.Item>

                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item required label="Почтовый адрес">
                    <Input.Group>
                      <Form.Item
                          name={["postAddress", "countryId"]}
                          rules={[{ required: true, message: "Страна обязательное поле" }]}
                      >
                        <Select
                            placeholder="Страна"
                            allowClear={false}
                            showSearch
                            options={countries?.response}
                            filterOption={(inputValue, option) => filterSelectOption(inputValue, option?.label || "")}
                            disabled={isAddressesMatches}
                            onChange={onLegalAddressChange}
                        />
                      </Form.Item>

                      <Tooltip title="Республика, автономная область, край, город федерального назначения, специальная территория"
                               placement="topRight"
                               color="blue">
                        <Form.Item
                            name={["postAddress", "region"]}
                            rules={[{ required: true, message: "Республика/автономная область/край/город федерального назначения/специальная территория обязательное поле" }]}
                        >
                          <Input placeholder="Республика, автономная область, край, город федерального назначения, специальная территория" disabled={isAddressesMatches} />
                        </Form.Item>
                      </Tooltip>

                      <Form.Item
                          name={["postAddress", "district"]}
                      >
                        <Input placeholder="Район" disabled={isAddressesMatches} />
                      </Form.Item>

                      <Form.Item
                          name={["postAddress", "city"]}
                          rules={[{ required: postAddressRequired, message: "Город или населенный пункт обязательное поле" }]}
                      >
                        <Input placeholder="Город" disabled={isAddressesMatches} />
                      </Form.Item>

                      <Form.Item
                          name={["postAddress", "settlement"]}
                          rules={[{ required: postAddressRequired, message: "Город или населенный пункт обязательное поле" }]}
                      >
                        <Input placeholder="Населенный пункт" disabled={isAddressesMatches} />
                      </Form.Item>

                      <Form.Item
                          name={["postAddress", "street"]}
                          rules={[{ required: true, message: "Улица обязательное поле" }]}
                      >
                        <Input placeholder="Улица" disabled={isAddressesMatches} />
                      </Form.Item>

                      <Form.Item
                          name={["postAddress", "buildingNumber"]}
                          rules={[{ required: true, message: "Номер дома/корпуса обязательное поле" }]}
                      >
                        <Input placeholder="Дом, корпус" disabled={isAddressesMatches} />
                      </Form.Item>

                      <Form.Item
                          name={["postAddress", "roomNumber"]}
                      >
                        <Input placeholder="Номер помещения" disabled={isAddressesMatches} />
                      </Form.Item>

                      <Form.Item
                          name={["postAddress", "postCode"]}
                          rules={[{ required: true, message: "Индекс обязательное поле" }, { len: 6, message: "Индекс должен состоять из 6 цифр" }]}
                      >
                        <Input maxLength={6} placeholder="Индекс" disabled={isAddressesMatches} />
                      </Form.Item>

                    </Input.Group>
                  </Form.Item>
                </Col>
              </Row>
          )}
        </Form>
      </Drawer>
    )
  }
)
