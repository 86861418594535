import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react"

import { PlusOutlined } from "@ant-design/icons"
import { Button, Checkbox, Col, Divider, Form, Row, Select, Space, Typography, FormInstance, Alert } from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import TextArea from "antd/lib/input/TextArea"

import { NumericInput } from "@/components/_Shared/NumericInput"
import { DeliveryAddressesAddForm, DeliveryAddressesAddFormProps } from "@/components/DeliveryAddresses/DeliveryAddressesAddForm/DeliveryAddressesAddForm"

import { OrderAddFormValues } from "../OrderAddForm"

import { useDeliveryAddressForOrderQuery } from "@/hook/Dictionaries/useDeliveryAddressQuery"

import { IDelivery, IDeliveryDeliveryForm } from "@/types/IDelivery"
import { IDeliveryAddress } from "@/types/IDeliveryAddress"

import { filterSelectOption } from "@/utils/filterSelectOption"
import { getMoney } from "@/utils/formatNumber"


const { Text } = Typography
export interface DeliveryFormRef {
  getAllAddresses(): IDeliveryAddress[]
  refetch(): void
}

export interface DeliveryFormProps {
  form: FormInstance<OrderAddFormValues>,
  isActive: boolean
  customerId: number
  addDeliveryAddresComponent: React.RefObject<DeliveryAddressesAddFormProps>
  setAddress(address: IDeliveryAddress | null): void
  initialValue?: IDeliveryDeliveryForm
  fuelsTotalAmount: number
}

export const DeliveryForm = forwardRef<DeliveryFormRef, DeliveryFormProps>(
  (props, ref) => {
    const { isActive, customerId, setAddress, initialValue, form, addDeliveryAddresComponent, fuelsTotalAmount } = props
    const [deliveryAddress, setDeliveryAddress] = useState<IDeliveryAddress>()
    const [deliveryCost, setDeliveryCost ] = useState<number | undefined>(0)

    const { data: addresses, isLoading: addressesIsLoading, refetch } = useDeliveryAddressForOrderQuery(customerId)

    useImperativeHandle(ref, () => ({
      getAllAddresses() {
        return allAddresses
      },
      refetch(){
        refetch()
      }
    }))

    useEffect(()=>{
      if (initialValue) {
        selectDeliveryAddress(initialValue.delivery?.deliveryAddress?.id ?? 0)
      }
    },[initialValue])


    // console.log(fuelsTotalAmount)
    useEffect(() => {
      updateDeliveryCost(deliveryAddress)
    }, [fuelsTotalAmount])

    const allAddresses: IDeliveryAddress[] = useMemo(() => {
      return [...(addresses?.response ?? [])]
    }, [addresses])

    const selectDeliveryAddress = (deliveryAddressId: number) => {
      const id = deliveryAddressId ?? initialValue?.delivery?.deliveryAddress?.id
      const deliveryAddressData = addresses?.response.find(x => x.id == id)

      updateDeliveryCost(deliveryAddressData)
      setDeliveryAddress(deliveryAddressData)
      setAddress(deliveryAddressData ?? null)
    }

    const updateDeliveryCost = (deliveryAddressData: IDeliveryAddress | undefined) => {
      let deliveryCost: number | undefined

      if (!deliveryAddressData?.deliveryCost || deliveryAddressData?.deliveryCost == 0) {
        deliveryCost = (deliveryAddressData?.defaultDeliveryCost ?? 0) * fuelsTotalAmount
      }
      else{
        deliveryCost = deliveryAddressData.deliveryCost * fuelsTotalAmount
      }

      setDeliveryCost(deliveryCost)
      form.setFieldValue("deliveryCost", deliveryCost)
    }

    const isDeliveryAddressWithEmptyCost: boolean = useMemo(() => {
      if (deliveryAddress == null) {
        return initialValue?.delivery?.deliveryAddress?.deliveryCost != null
          && initialValue.delivery?.deliveryAddress?.deliveryCost != 0
      }

      return deliveryAddress?.deliveryCost != null && deliveryAddress?.deliveryCost != 0
    }, [deliveryAddress, initialValue])

    return (
      <>
        <Row gutter={16}>
          <Col xs={24} md={24}>
            <Form.Item
              name="deliveryAddressId"
              label="Адрес доставки"
              rules={[
                {
                  required: isActive,
                  message: "Пожалуйста выберите адрес"
                }
              ]}
              initialValue={initialValue?.delivery?.deliveryAddress?.id}
            >
              <Select
                disabled={addressesIsLoading}
                placeholder="Адрес"
                showSearch
                onSelect={value => selectDeliveryAddress(value)}
                filterOption={(inputValue, option) =>
                  filterSelectOption(inputValue, option?.label || "")
                }
                options={allAddresses?.map((address) => ({
                  value: address.id,
                  label: address.fullAddress
                }))}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Space style={{ padding: "0 8px 4px" }}>
                      <Button type="text"onClick={ ()=> 
                        {
                          addDeliveryAddresComponent.current?.open(undefined)
                        } }>
                        Добавить адрес 
                      </Button>
                    </Space>  
                  </>
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24}>
            <Form.Item valuePropName="checked" name="deliveryCost" initialValue={initialValue?.deliveryCost}>
              {
                isDeliveryAddressWithEmptyCost  
                  ? <Text>Стоимость доставки:  { !deliveryCost || deliveryCost === 0 ? getMoney(form.getFieldValue("deliveryCost")) : getMoney(deliveryCost) } </Text>
                  :
                  <>
                    <Text >Стоимость доставки:  { getMoney(deliveryCost ?? 0) }</Text>
                    <Alert  message="Используется стандартное значение, цена  за доставку будет установлена позже" type="warning" showIcon/>
                  </> 
              }
            </Form.Item>
          </Col>
          <Col xs={24} md={24}>
            <Form.Item name="deliveryCommentary" label="Комментарий к доставке" initialValue={initialValue?.delivery?.commentary}>
              <TextArea   
              showCount
              maxLength={100}
              placeholder="Комментарий"
              style={{ height: 120, resize: "none" }}/>
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }
)
