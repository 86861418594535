import { FC, useRef } from "react"

import { Typography, Table, Tag, Button } from "antd"

import {
  DriverAddForm,
  DriverAddFormProps
} from "@/components/Drivers/DriverAddForm"

import classes from "./TransportTable.module.scss"
import { TransportAddForm, TransportAddFormProps } from "../TransportAddForm"

import { ITranportPairOrder } from "@/types/IOrder"
import { ITimeUnloadFuel } from "@/types/ITimeUnloadFuel"
import { ITableColumn } from "@/types/ui"

import { formatDriver, formatTransport } from "@/utils/formatString"

const { Text } = Typography

export interface TransportTableProps {
  transports: ITranportPairOrder[] | undefined
  orderDate?: Date
  additionalColumns?: any[]
  isDespetcher?: boolean
}

export const TransportTable: FC<TransportTableProps> = (props) => {
  const { transports, additionalColumns, orderDate, isDespetcher } = props

  const driverComponent = useRef<DriverAddFormProps>(null)
  const transportComponent = useRef<TransportAddFormProps>(null)

  const transportColumns = [
    {
      title: "Водитель",
      dataIndex: ["driver"],
      key: "driver",
      render: (_: any, transportPair: ITranportPairOrder) => 
        formatDriver(transportPair.driver),
      isVisible: true,
      order: 1
    },
    {
      title: "Транспорт",
      dataIndex: ["truck"],
      key: "truck",
      render: (_: any, transportPair: ITranportPairOrder) => 
        formatTransport(transportPair.truck),
      isVisible: true,
      order: 2
    },
    {
      title: "Период загрузки ",
      dataIndex: ["timeUnloadFuel"],
      key: "timeUnloadFuel",
      render: (_: any, transportPair: ITranportPairOrder) => transportPair.wishTimeSlot ? 
      `${orderDate?.toLocaleDateString()} ${transportPair.wishTimeSlot}` : "-",
      isVisible: !isDespetcher,
      order: 3
    }
  ]


  return (
    <>
      <DriverAddForm ref={driverComponent}></DriverAddForm>
      <TransportAddForm ref={transportComponent}></TransportAddForm>
      <Table
        columns={[...transportColumns.filter(c=>c.isVisible), ...(additionalColumns ?? [])]}
        pagination={false}
        rowKey={(transport) => transport.id}
        expandable={{
          expandedRowRender: (transportPair) => formatTransport(transportPair.pickup),
          rowExpandable: (transport) =>
            transport.pickup != undefined && transport.pickup != null,
          defaultExpandAllRows: true
        }}
        dataSource={transports}
      />
    </>
  )
}
