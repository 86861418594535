import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import {
  IDriver,
  IDriverCreate,
  IDriverUpdate,
  IDriverChangeVerificationStatus,
  IDriverSelectListItem, IUploadDriverPassportScansRequest
} from "@/types/IDriver"
import { ITableColumn } from "@/types/ui"

export const getDriver = (
  params: IRequestParams,
  customerId?: number
): Promise<IPagingResponse<IDriver>> =>
  client.get(`/Drivers${customerId ? "/" + customerId : ""}`, {
    params
  })

export const getDriversSelectList = (): Promise<IResponse<IDriverSelectListItem[]>> => client.get("Drivers/selectList")

export const getDriverForOrder = (
  orderId: number,
  customerId?: number
): Promise<IResponse<IDriver[]>> =>
  client.get(`/Drivers/Order/${orderId}${customerId ? "/" + customerId : ""}`)

export const getDriverById = (id: number): Promise<IResponse<IDriver>> =>
  client.get(`/Drivers/ById/${id}`)

export const createDriver = (body: IDriverCreate): Promise<IResponse<number>> =>
  client.post("/Drivers", body)

export const updateDriver = (body: IDriverUpdate): Promise<IResponse<string>> =>
  client.put("/Drivers", body)

export const getDriverExcel = (
  params: IRequestParams,
  columns?: ITableColumn<IDriver>[]
): Promise<IResponse<IDownloadDocument>> =>
  client.post("/Drivers/Excel", columns, {
    params
  })

export const deleteDrivers = (ids: number[]): Promise<IResponse<boolean>> =>
  client.delete("/Drivers", {
    data: {
      ids
    }
  })

  export const changeVerificationDriver = (body: IDriverChangeVerificationStatus): Promise<IResponse<string>> =>
    client.put("/Drivers/verificaton-driver", body)

export const uploadDriverPassportScans = (body: IUploadDriverPassportScansRequest): Promise<IResponse<never>> =>
  client.post("/Drivers/passport", body)
