export const formatNumber = (value: number, digits: number = 2) => {
    return (Math.round(value * 100) / 100).toFixed(digits)
}

export const formatFuelAmount = (value: number) => {
    return Number(value.toFixed(3))
}

export const formatFuelVolume = (value: number) => {
    return Number(value.toFixed(2))
}

export const getMoney = (value: number, digits: number = 2) => {
    let valueStr: string = value.toFixed(digits).toString()
    return valueStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ₽"
}

export const getPositiveOrZero = (value: number) => {
    return value < 0 ? 0 : value
}

export const roundTo = (value: number, digits: number) => {
    if (digits === undefined) {
        digits = 0
    }

    const multiplicator = Math.pow(10, digits)
    value = parseFloat((value * multiplicator).toFixed(11))
    const rounded = Math.round(value) / multiplicator
    return +(rounded.toFixed(digits))
}
