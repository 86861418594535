import { useQuery } from "@tanstack/react-query"

import { USER_ADMIN_QUERY_KEY, USER_QUERY_KEY, USER_SELECT_LIST_BY_ROLE_QUERY_KEY } from "@/constants/query"
import { getUsers, getUserById, getUsersByCustomer, getUserByIdForAdmin, getUserSelectListByRoleName } from "@/http/user"

import { IRequestParams } from "@/types/api"

export const useUsersQuery = (params?: IRequestParams) => {
    return useQuery({
        queryFn: () => getUsers(params || {}),
        queryKey: [USER_QUERY_KEY, params],
        retry: false
    })
}

export const useUserByIdQuery = (id: string) => {
    return useQuery({
        queryFn: () => getUserById(id),
        queryKey: [USER_QUERY_KEY, id],
        enabled: !!id
    })
}

export const useUserByIdForAdminQuery = (id: string) => {
    return useQuery({
        queryFn: () => getUserByIdForAdmin(id),
        queryKey: [USER_ADMIN_QUERY_KEY, id],
        enabled: !!id
    })
}

export const useUsersByCustomerQuery = (id: number, params?: IRequestParams) => {
    return useQuery({
      queryFn: () => getUsersByCustomer(params || {}, id),
      queryKey: [USER_QUERY_KEY, params],
      retry: false
    })
  }

  export const useUserSelectListQuery = (roleName: string) => {
    return useQuery({
        queryFn: () => getUserSelectListByRoleName(roleName),
      queryKey: [USER_SELECT_LIST_BY_ROLE_QUERY_KEY, roleName],
      retry: false
    })
  }
