import { FC } from "react"

import { Divider, Flex, Layout } from "antd"

import { UserMenu } from "@/components/_Shared/UserMenu"

import classes from "./Header.module.scss"
import { LogoSidebar } from "../../LogoWrapper/Sidebar"

export const Header: FC = () => {

  return (
    <Layout.Header className={classes.header}>
      <Flex className={classes.flexColumn}>
        <Flex
          justify="space-between"
          align="center" className={classes.flexBox}>
          <LogoSidebar shown={true} />
          <UserMenu />
        </Flex>
        <Divider className={classes.divider} />
      </Flex>
    </Layout.Header>
  )
}
