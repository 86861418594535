
interface SvgImageProps {
    src: string;
    width?: number;
    height?: number;
    styles?: React.CSSProperties;
    className?: string;
}

export const SvgImage = (props: SvgImageProps) => {

    let width = props.width ?? 100
    let height = props.height ?? 100

    return (
        <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" style={props.styles} className={props.className}>
            <image href={props.src} width={width} height={height} />
        </svg>
    )
}