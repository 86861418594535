import React from "react"

import { OidcConfiguration, OidcProvider } from "@axa-fr/react-oidc"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ConfigProvider } from "antd"
import ruRU from "antd/locale/ru_RU"
import dayjs from "dayjs"
import "dayjs/locale/ru"
import { BrowserRouter } from "react-router-dom"


import variables from "@/_styles/variables.module.scss"
import { CustomerContextProvider } from "@/context/CustomerContext"
import { PermissionContextProvider } from "@/context/PermissionContext"
import { TimeCoordinateProvider } from "@/context/TimeCoordinateContext"
import { ErrorBoundary } from "@/hoc/ErrorBoundary"
import { AuthorizationCallBackSuccess } from "@/pages/AuthorizationCallBackSuccess"
import { Router } from "@/router"

import { NotificationProvider } from "./context/NotificationContext"
import { SignalRProvider } from "./context/SignalRContext"

const configuration: OidcConfiguration = {
  client_id: process.env.REACT_APP_CLIENT_ID ?? "",
  redirect_uri: window.location.origin + "/authentication/callback",
  silent_redirect_uri: window.location.origin + "/authentication/silent-callback",
  scope: "openid profile email offline_access",
  authority: process.env.REACT_APP_AUTHORITY ?? "",
  service_worker_only: false,
  storage: localStorage,
  token_request_extras: {
    client_secret: process.env.REACT_APP_CLIENT_SECRET ?? ""
  }
}

const client = new QueryClient()

dayjs.locale("ru")

export const App = () => {
  return (
    <OidcProvider
      configuration={configuration}
      callbackSuccessComponent={AuthorizationCallBackSuccess}>
      <QueryClientProvider client={client}>
        <ConfigProvider
          locale={ruRU}
          theme={{
            token: {
              colorPrimary: variables.basecolor,
              colorBgLayout: variables.basecolordark, //для видимых скруглений
              fontFamily: "Commissioner",
              colorBgContainer: variables.baseBgColorContent,
              colorTextSecondary: variables.textGraySubTitleColor
            },
            components: {
              Layout: {
                siderBg: variables.basecolordark,
                headerBg: variables.baseBgColor,
                headerPadding: 0
              },
              Menu: {
                darkItemBg: variables.basecolordark,
                darkSubMenuItemBg: variables.basecolordark,
                itemActiveBg: variables.baseBgColor,
                darkItemSelectedBg: variables.baseBgColor,
                darkItemSelectedColor: variables.textMenuSelected,
                darkPopupBg: variables.basecolordark
              },
              Segmented: {
                itemSelectedBg: variables.basecolor,
                itemSelectedColor: variables.baseBgColorContent,
                colorBgLayout: variables.baseBgColor
              },
              DatePicker: {
                timeColumnWidth: 100
              },
              Table: {
                fontSize: 12,
                headerColor: variables.tableSubTitleColor,
                rowExpandedBg: "inherit"
              },
              Descriptions: {
                fontSize: 12
              },
              Typography: {
                colorText: variables.textGraySubTitleColor
              },
              Tabs: {
                cardBg: variables.baseBgColorContent,
                itemColor: variables.textTabColor,
                itemActiveColor: variables.basecolor,
                fontWeightStrong: 600
              }
            }
          }}
        >
          <PermissionContextProvider>
            <CustomerContextProvider>
              <SignalRProvider>
                <NotificationProvider>
                  <TimeCoordinateProvider>
                    <React.StrictMode>
                      <ErrorBoundary>
                        <BrowserRouter>
                          <Router />
                        </BrowserRouter>
                      </ErrorBoundary>
                    </React.StrictMode>
                  </TimeCoordinateProvider>
                </NotificationProvider>
              </SignalRProvider>
            </CustomerContextProvider>
          </PermissionContextProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </OidcProvider>
  )
}
