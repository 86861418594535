import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IUser, IUserChangePassword, IUserCreate, IUserSelectListItem, IUserUpdate } from "@/types/IUser"

export const getUsers = (
  params: IRequestParams
): Promise<IPagingResponse<IUser>> =>
  client.get("/Users", {
    params
  })

  export const getUsersByCustomer = (params: IRequestParams,  id: number): Promise<IPagingResponse<IUser>> =>
  client.get(`/Users/byCustomer/${id}`, {
    params
  })

export const getUserSelectListByRoleName = (roleName: string): Promise<IResponse<IUserSelectListItem[]>> =>
  client.get(`/Users/selectList/${roleName}`)

export const getUserById = (id: string): Promise<IResponse<IUser>> =>
  client.get(`/Users/${id}`)

export const getUserByIdForAdmin = (id: string): Promise<IResponse<IUser>> =>
  client.get(`/Users/admin/${id}`)

export const createUser = (user: IUserCreate): Promise<IResponse<boolean>> =>
  client.post("/Users", user)

export const updateUser = (user: IUserUpdate): Promise<IResponse<boolean>> =>
  client.put("/Users", user)

export const changePasswordUser = (user: IUserChangePassword): Promise<IResponse<boolean>> =>
  client.put("/Users/changePassword", user)

export const deleteUsers = (ids: string[]): Promise<IResponse<boolean>> =>
  client.delete("/Users", {
    data: {
      ids
    }
  })
