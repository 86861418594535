import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { VerificationStatus } from "@/types/IDriver"
import { ICountEntityVerificationCatalog, IInformationNotification, INotification, IUpdateReadStatusNotifications } from "@/types/INotificaiton"

export const getNotificaitonsByCurrentUser = (params: IRequestParams): Promise<IPagingResponse<INotification>> =>
     client.get("/Notifications",{ params })

export const getNotReadNotificaitonsByCurrentUser = (countRecord?: number): Promise<IResponse<INotification[]>> =>
     client.get(`/Notifications/not-read?countRecord=${countRecord ?? undefined}`)

export const getCountByAwaitsVerificationStatus = (): Promise<IResponse<ICountEntityVerificationCatalog>> =>
     client.get("/Notifications/count-by-verification")

export const getNotReadCountNotificaitonsByCurrentUser = (): Promise<IResponse<number>> =>
     client.get("/Notifications/not-read-count")

export const updateReadStatusForNotifications = (body: IUpdateReadStatusNotifications): Promise<IResponse<boolean>> => 
     client.put("/Notifications/read-status", body) 

export const readAllNotReadNotifications = (): Promise<IResponse<boolean>> => 
     client.put("/Notifications/read-all") 

export const sendInformationNotifications = (body:IInformationNotification):Promise<IResponse<boolean>> =>
     client.post("/Notifications/send/information", { message: body.message }) 