import { FC } from "react"

import { Button } from "antd"
import { useNavigate } from "react-router-dom"

import classes from "./LogoSidebar.module.scss"
import { SvgImage } from "../../SvgImage/SvgImage"


interface ILogoSidebarProps {
    shown: boolean
}

export const LogoSidebar: FC<ILogoSidebarProps> = (props) => {
    const appName = process.env.REACT_APP_APP_NAME
    const { shown } = props

    let navigate = useNavigate()
    const routeChange = () => {
        navigate("/")
    }

    return (
        <Button type="text" shape="round" size="large" className={classes.logo + " " + (shown ? classes.logoShown : classes.logoHidden)} onClick={routeChange}>
            <SvgImage src="logo.svg" width={117} height={24} />
         </Button>
    )
}