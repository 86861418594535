import { useState } from "react"

import { Checkbox, Divider, Flex, Select } from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import { Dayjs } from "dayjs"

import CustomerAnalyticsSettings, {
  CustomerAnalyticsSettingsOptions
} from "@/components/Analytics/AnalyticsSettings/CustomerAnalytics/CustomerAnalyticsSettings"

import classes from "../AnalyticsSettings.module.scss"

import { useDepotSelectListQuery } from "@/hook/Dictionaries/useDepotQuery"
import { useFuelTypeQuery } from "@/hook/Dictionaries/useFuelTypeQuery"

import { BaseDisplayUnit, baseDisplayUnitsMap, IAnalyticsPageDisplayUnit } from "@/types/IAnalytics"
import { Period } from "@/types/IOrderReport"

export interface useCustomerAnalyticsHeaderProps {
  baseDisplayUnits: BaseDisplayUnit[]
  additionalDisplayUnits?: IAnalyticsPageDisplayUnit[]
}


export const useCustomerAnalyticsHeader = (props: useCustomerAnalyticsHeaderProps) => {
  const { baseDisplayUnits, additionalDisplayUnits } = props
  const displayUnits = [
    ...baseDisplayUnits.map(u => baseDisplayUnitsMap[u]),
    ...(additionalDisplayUnits ?? [])
  ]

  const [optionsList, setOptionsList] = useState<CustomerAnalyticsSettingsOptions[]>([{
    depotId: undefined,
    fuelTypeId: undefined,
    startDate: undefined,
    endDate: undefined,
    activePeriod: Period.Year
  }])
  const [compare, setCompare] = useState(false)
  const [displayUnit, setDisplayUnit] = useState<IAnalyticsPageDisplayUnit>(displayUnits[0])

  const onCompareChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked
    const newOptionsCount = checked ? 2 : 1

    setOptionsList(() => {
      const newOptionsList = optionsList
      if (newOptionsCount === 1) {
        return [newOptionsList[0]]
      } else {
        const oldOptions = optionsList[0]

        for (let i = 1; i < newOptionsCount; i++) {
          newOptionsList.push({
            ...oldOptions
          })
        }
      }

      return newOptionsList
    })
    setCompare(checked)
  }

  const getOnFuelTypeChangeFunction = (optionsId: number) => {
    if (optionsId < 1)
      return

    return (fuelTypeId: number | undefined) => {
      setOptionsList(prev => {
        const newOptions = [...prev]
        const thisOptions = newOptions[optionsId - 1]
        thisOptions.fuelTypeId = fuelTypeId
        return newOptions
      })
    }
  }

  const getOnRangeChangeFunction = (optionsId: number) => {
    if (optionsId < 1)
      return

    return (dates: [start: Dayjs | null, end: Dayjs | null] | null, dateStrings: [string, string]) => {
      if (!dates || dates.length === 2) {
        setOptionsList(prev => {
          const newOptions = [...prev]
          const thisOptions = newOptions[optionsId - 1]
          thisOptions.startDate = dates ? dates[0]?.format("YYYY-MM-DD") : undefined
          thisOptions.endDate = dates ? dates[1]?.format("YYYY-MM-DD") : undefined
          thisOptions.activePeriod = dates ? undefined : Period.Year
          return newOptions
        })
      }
    }
  }

  const getOnPeriodChangeFunction = (optionsId: number) => {
    if (optionsId < 1)
      return

    return (period: Period) => {
      if (!isNaN(period) && period >= 0) {
        setOptionsList(prev => {
          const newOptions = [...prev]
          const thisOptions = newOptions[optionsId - 1]
          thisOptions.startDate = undefined
          thisOptions.endDate = undefined
          thisOptions.activePeriod = period
          return newOptions
        })
      }
    }
  }

  const getOnDepotChangeFunction = (optionsId: number) => {
    if (optionsId < 1)
      return

    return (depotId: number | undefined) => {
      setOptionsList(prev => {
        const newOptions = [...prev]
        const thisOptions = newOptions[optionsId - 1]
        thisOptions.depotId = depotId
        return newOptions
      })
    }
  }

  const component =
    <CustomerAnalyticsHeader
      onCompareChange={onCompareChange}
      getOnPeriodChangeFunction={getOnPeriodChangeFunction}
      getOnRangeChangeFunction={getOnRangeChangeFunction}
      getOnFuelTypeChangeFunction={getOnFuelTypeChangeFunction}
      getOnDepotChangeFunction={getOnDepotChangeFunction}
      onDisplayUnitChange={setDisplayUnit}
      optionsList={optionsList}
      displayUnit={displayUnit}
      displayUnits={displayUnits}
      isComparison={compare}/>

  return {
    optionsList,
    component,
    displayUnit: displayUnit,
    isComparison: compare
  }
}


interface CustomerAnalyticsHeaderProps {
  optionsList: CustomerAnalyticsSettingsOptions[]
  onCompareChange: (e: CheckboxChangeEvent) => void
  getOnPeriodChangeFunction: (optionsId: number) => ((period: Period) => void) | undefined
  getOnRangeChangeFunction: (optionsId: number) => ((dates: [start: Dayjs | null, end: Dayjs | null] | null, dateStrings: [string, string]) => void) | undefined
  getOnFuelTypeChangeFunction: (optionsId: number) => ((fuelTypeId: (number | undefined)) => void) | undefined
  getOnDepotChangeFunction: (optionsId: number) => ((depotId: (number | undefined)) => void) | undefined
  onDisplayUnitChange: ((newUnit: IAnalyticsPageDisplayUnit) => void) | undefined
  displayUnit: IAnalyticsPageDisplayUnit
  displayUnits: IAnalyticsPageDisplayUnit[]
  isComparison: boolean
}


const CustomerAnalyticsHeader = (props: CustomerAnalyticsHeaderProps) => {
  const {
    optionsList,
    onCompareChange,
    getOnPeriodChangeFunction,
    getOnRangeChangeFunction,
    getOnFuelTypeChangeFunction,
    getOnDepotChangeFunction,
    onDisplayUnitChange,
    isComparison,
    displayUnit,
    displayUnits
  } = props

  const { data: depots } = useDepotSelectListQuery()
  const { data: fuelTypes } = useFuelTypeQuery()

  return (
    <Flex gap="small" vertical>
      <Flex align="center" gap="middle">
        <Select
          className={classes["unit-switch"]}
          onSelect={(_, tuple) => onDisplayUnitChange && onDisplayUnitChange(tuple)}
          placeholder="Отображение"
          value={displayUnit.value}
          options={displayUnits}
        />
        <Checkbox
          checked={isComparison}
          onChange={onCompareChange}>
          Сравнить
        </Checkbox>
      </Flex>
      <Flex gap="large">
        <CustomerAnalyticsSettings
          fuelTypes={fuelTypes}
          depots={depots?.response}
          options={optionsList[0]}
          onRangeChange={getOnRangeChangeFunction(1)}
          onPeriodChange={getOnPeriodChangeFunction(1)}
          onChosenFuelTypeChange={getOnFuelTypeChangeFunction(1)}
          onChosenDepotChange={getOnDepotChangeFunction(1)}
          isComparison={isComparison}/>
        {
          isComparison &&
            <Divider className={classes["comparison-options-divider"]} type="vertical"/>
        }
        {
          isComparison &&
            <CustomerAnalyticsSettings
                fuelTypes={fuelTypes}
                depots={depots?.response}
                options={optionsList[1]}
                onRangeChange={getOnRangeChangeFunction(2)}
                onPeriodChange={getOnPeriodChangeFunction(2)}
                onChosenFuelTypeChange={getOnFuelTypeChangeFunction(2)}
                onChosenDepotChange={getOnDepotChangeFunction(2)}
                isComparison={isComparison}/>
        }
      </Flex>
    </Flex>
  )
}