import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react"

import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Skeleton,
  Typography,
  UploadFile
} from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import dayjs from "dayjs"
import { useSearchParams } from "react-router-dom"
import { uid } from "uid"

import { DatePicker } from "@/components/_Shared/DatePicker"
import { PicturesUpload } from "@/components/_Shared/PicturesUpload"
import { CustomerContext } from "@/context/CustomerContext"
import { getCustomerClientsSelectList } from "@/http/customerClient"

import { useTransportModelsQuery } from "@/hook/Dictionaries/useTransportModelQuery"
import { useTransportByIdQuery } from "@/hook/Dictionaries/useTransportQuery"
import { useTransportsMutation } from "@/hook/Dictionaries/useTransportsMutation"
import { useTransportTypesQuery } from "@/hook/Dictionaries/useTransportTypeQuery"

import {
  IDocumentFile,
  ITransport,
  ITransportCertificateType,
  ITransportClass,
  ITransportCreate,
  ITransportType,
  TransportCertificateTypes, TransportCertificateTypesGenitives,
  TransportClassForSelect
} from "@/types/ITransport"
import { ISelectListItem } from "@/types/ui"

import { filterSelectOption } from "@/utils/filterSelectOption"
import { uploadFileConvert } from "@/utils/uploadFileConvert"

const { Text } = Typography

export interface TransportAddFormProps {
  open(id?: number, isCustomerEdit?: boolean): void

  close(): void
}

interface TransportAddFormValues {
  modelId: number
  typeId: number
  modelName: string
  typeName: string
  class: ITransportClass
  number: string
  type: ITransport
  region: string
  brand: string
  color: string
  vin: string
  owner: string
  weightWithoutLoad: number
  permittedMaximumMass: number
  axlesNumber: number
  liter: number
  certificateType: ITransportCertificateType
  certificateNumber: string
  certificateTypeId: number
  dateExpirationCertificate: string
  dateBeginningCertificate: string
  section1: number
  section2: number
  section3: number
  section4: number
  section5: number
  section6: number
  section7: number
  section8: number
  clientIds: number[]
  stsScans?: IDocumentFile[]
  stsScan: number| undefined
}

export const TransportAddForm = forwardRef<TransportAddFormProps>(
  (props, ref) => {
    const customerContext = useContext(CustomerContext)

    const [form] = Form.useForm<TransportAddFormValues>()
    const [id, setId] = useState<number>(0)
    const [isOpen, setIsOpen] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [isCreateNewModel, setIsCreateNewModel] = useState<boolean>(false)
    const [certificateType, setCertificateType] = useState<ISelectListItem | undefined>(undefined)

    const [searchParams, setSearchParams] = useSearchParams()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalTitle, setModalTitle] = useState("")
    const [modalText, setModalText] = useState("")

    const [isCustomerEdit, setIsCustomerEdit] = useState<boolean>(false)
    const [data, setData] = useState<TransportAddFormValues>()
    const [clients, setClients] = useState<ISelectListItem[]>([])

    const { data: transport, isLoading } = useTransportByIdQuery(id)
    const { data: transportTypes } = useTransportTypesQuery()
    const { data: transportModels } = useTransportModelsQuery()
    const [transportTypesForSelect, setTransportTypesForSelect] = useState<
      ITransportType[]
    >(transportTypes?.response.data ?? [])
    const [ hideTankerFields, setHideTankerFields ] = useState<boolean>(true)
    const [fileList, setFileList] = useState<UploadFile[]>([])
    
    const { createMutation, updateMutation, uploadSTSScansMutation } = useTransportsMutation()

    useImperativeHandle(ref, () => ({
      open(id?: number, isCustomerEdit?: boolean) {
        onOpenHandler(id, isCustomerEdit)
      },
      close() {
        onCloseHandler()
      }
    }))

    useEffect(() => {
      if (transport?.response?.stsScans) {
        const newPromises = transport.response.stsScans.map((file) =>
          uploadFileConvert({
            url: file.content,
            name: file.name,
            uid: uid()
          })
        )
        Promise.all(newPromises).then((res) => {
          onFileListChange(res)
        })
      }
    }, [transport?.response.stsScans])

    useEffect(() => {
      if (isOpen && !customerContext.isCustomer()) {
        getCustomerClientsSelectList().then((res) => setClients(res.response))
      }

      if (transport && isOpen) {
        const response = transport.response
        form.setFields([
          {
            name: "number",
            value: response.number
          },
          {
            name: "class",
            value: response.class
          },
          {
            name: "modelId",
            value: response.modelId
          },
          {
            name: "typeId",
            value: response.typeId
          },
          {
            name: "region",
            value: response.region
          },
          {
            name: "brand",
            value: response.brand
          },
          {
            name: "color",
            value: response.color
          },
          {
            name: "vin",
            value: response.vin
          },
          {
            name: "owner",
            value: response.owner
          },
          {
            name: "weightWithoutLoad",
            value: response.weightWithoutLoad
          },
          {
            name: "permittedMaximumMass",
            value: response.permittedMaximumMass
          },
          {
            name: "axlesNumber",
            value: response.axlesNumber
          },
          {
            name: "liter",
            value: response.liter
          },
          {
            name: "certificateTypeId",
            value: response.certificateType
          },
          {
            name: "certificateNumber",
            value: response.certificateNumber
          },
          {
            name: "dateBeginningCertificate",
            value:
              (response.dateBeginningCertificate && response.dateBeginningCertificate.length > 0)
                ? dayjs(response.dateBeginningCertificate, "DD/MM/YYYY")
                : null
          },
          {
            name: "dateExpirationCertificate",
            value:
              (response.dateExpirationCertificate && response.dateExpirationCertificate.length > 0)
                ? dayjs(response.dateExpirationCertificate, "DD/MM/YYYY")
                : null
          },
          {
            name: "section1",
            value: response.section1
          },
          {
            name: "section2",
            value: response.section2
          },
          {
            name: "section3",
            value: response.section2
          },
          {
            name: "section4",
            value: response.section4
          },
          {
            name: "section5",
            value: response.section5
          },
          {
            name: "section6",
            value: response.section6
          },
          {
            name: "section7",
            value: response.section7
          },
          {
            name: "section8",
            value: response.section8
          }
        ])

        setHideTankerFields(response.class === ITransportClass.Truck)
        setCertificateType(((response.certificateType !== undefined) && (response.certificateType !== null))
        ? TransportCertificateTypes.find(type => type.value === response.certificateType) : undefined)

        form.setFieldValue("clientIds", response.clients.map(c => c.id))

        setTransportTypesForSelect(
          transportTypes?.response.data.filter(
            (t) => t.class === response.class
          ) ?? []
        )
      }
    }, [id, transport, form, isOpen])

    const formTransportText = useMemo(() => {
      return id === 0 ? "Внести" : "Изменить"
    }, [id])

    const title = useMemo(() => {
      if (id) {
        return "Редактировать"
      }

      return "Добавить"
    }, [id])

    const setOrDeleteQueryParams = (name: string, value?: string) => {
      if (value) searchParams.set(name, value)
      else searchParams.delete(name)

      setSearchParams(searchParams)
    }

    const onOpenHandler = (id?: number, isCustomerEdit?: boolean) => {
      if (id) {
        setId(id)
      }

      setIsCustomerEdit(isCustomerEdit ?? false)
      form.resetFields()
      setCertificateType(undefined)
      setHideTankerFields(true)
      setFileList([])
      setIsOpen(true)
      setOrDeleteQueryParams("transportAddForm", "true")
    }
    const onTransportClassChange = (e: number) => {
      setTransportTypesForSelect(
        transportTypes?.response.data.filter((t) => t.class === e) ?? []
      )
      const transportClass = form.getFieldValue("class")

      form.resetFields(["typeId", "modelId"])
      if (transportClass === ITransportClass.Truck) {
        setHideTankerFields(true)
        setCertificateType(undefined)
        form.resetFields([
          "certificateTypeId",
          "certificateNumber",
          "dateBeginningCertificate",
          "dateExpirationCertificate",
          "liter",
          "section1",
          "section2",
          "section3",
          "section4",
          "section5",
          "section6",
          "section7",
          "section8"
          //...Array.from({ length: 8 }, (_, i) => i + 1).map((index) => `section${index}`)
          ])
      }
      else {
        setHideTankerFields(false)
      }
    }

    const onFileListChange = (file: UploadFile | UploadFile[]) => {
      if (Array.isArray(file)) {
        setFileList(file)
        if (file.length > 0) {
          form.setFieldValue("stsScan", file.length)
        }
        else {
          // Если не будет файлов, кидает ошибку валидации.
          // Нужно потому что длину 0 тоже воспринимает как значение.
          // А на самом деле это не должно быть валидным.
          form.setFieldValue("stsScan", undefined)
        }
      } else {

        setFileList((prevState) => [...prevState, file])
        form.setFieldValue("stsScan", fileList.length)
      }
    }

    const onCreateNewModelChange = (e:CheckboxChangeEvent) => {
      setIsCreateNewModel(e.target.checked)
      form.setFieldValue("modelId",undefined)
      form.setFieldValue("modelName",undefined)
    }

    const tryTransport = async (data: TransportAddFormValues | undefined) => {
      try {
        if (data === undefined) {
          return
        }

        const request: ITransportCreate = {
          modelId: data.modelId,
          typeId: data.typeId,
          modelName: data.modelName,
          typeName: data.typeName,
          class: data.class,
          number: data.number,
          approved: true,
          region: data.region,
          brand: data.brand,
          color: data.color,
          vin: data.vin,
          owner: data.owner,
          weightWithoutLoad: data.weightWithoutLoad,
          permittedMaximumMass: data.permittedMaximumMass,
          axlesNumber: data.axlesNumber,
          liter: data.liter,
          certificateType: certificateType?.value,
          certificateNumber: data.certificateNumber,
          dateExpirationCertificate: data.dateExpirationCertificate,
          dateBeginningCertificate: data.dateBeginningCertificate,
          section1: data.section1,
          section2: data.section2,
          section3: data.section3,
          section4: data.section4,
          section5: data.section5,
          section6: data.section6,
          section7: data.section7,
          section8: data.section8,
          clientIds: customerContext.isCustomer()
            ? customerContext.customer?.customerClientId
              ? [customerContext.customer?.customerClientId]
              : []
            : data.clientIds
        }

        const stsScans = fileList.map((file) => ({
          content: (file.url as string).split("base64,")[1],
          name: file.name
        }))

        setIsProcessing(true)

        if (id) {
          if (isCustomerEdit) {
            await uploadSTSScansMutation.mutateAsync({
              stsScans,
              transportId: Number(id)
            })
          } else {
            request.stsScans = stsScans
            await updateMutation.mutateAsync({
              ...request,
              id: id
            })
          }
        } else {
          await createMutation.mutateAsync(request).then(response => {
            if (response.succeeded)
              uploadSTSScansMutation.mutateAsync({
                stsScans,
                transportId: Number(response.response)
              })
          })
        }

        form.resetFields()
        onCloseHandler()
      } finally {
        setIsProcessing(false)
      }
    }
    const onFinish = (data: TransportAddFormValues) => {
      setData(data)
      setModalTitle("Предупреждение")
      setModalText(`Просим Вас проверить данные согласно СТС, в случае если данные указаны не корректно или не соответствуют данным в документе
      – в записи на нефтебазу будет отказано!)`)
      setIsModalOpen(true)
    }

    const onCloseHandler = () => {
      setId(0)
      setIsCustomerEdit(false)
      form.resetFields()
      setData(undefined)
      setIsOpen(false)
      setIsCreateNewModel(false)
      setOrDeleteQueryParams("transportAddForm")
    }

    const handleOk = async () => {
      setIsModalOpen(false)
      await tryTransport(data)
    }

    const handleCancel = () => {
      setIsModalOpen(false)
    }

    const certificateTypeGenitive = certificateType
      ? " " + TransportCertificateTypesGenitives[certificateType.value]
      : ""

    return (
      <Drawer
        title={title}
        width={820}
        onClose={onCloseHandler}
        open={isOpen}
        styles={{ body: { paddingBottom: 80 } }}
        extra={
          <Button type="primary" disabled={isProcessing} onClick={form.submit}>
            {formTransportText}
          </Button>
        }
      >
        <Modal
          title={modalTitle}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Text>{modalText}</Text>
        </Modal>
        <Skeleton loading={id === 0 ? false : isLoading}>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            {
              !isCustomerEdit &&
              <>
                  <Form.Item
                      hidden={customerContext.isCustomer()}
                      name="clientIds"
                      label="Клиент"
                      rules={[
                        {
                          required: !customerContext.isCustomer(),
                          message: "Пожалуйста выберите клиента"
                        }
                      ]}
                  >
                      <Select
                          mode="multiple"
                          placeholder="Клиент"
                          showSearch
                          options={clients}
                      />
                  </Form.Item>
                  <Form.Item
                      name="class"
                      label="Вид транспорта"
                      rules={[
                        {
                          required: true,
                          message: "Пожалуйста выберите вид транспорта"
                        }
                      ]}
                  >
                      <Select
                          onChange={(e: number) => onTransportClassChange(e)}
                          placeholder="Вид транспорта"
                          showSearch
                          filterOption={(inputValue, option) =>
                            filterSelectOption(inputValue, option?.label || "")
                          }
                          options={TransportClassForSelect.map((type) => ({
                            value: type.id,
                            label: type.value
                          }))}
                      />
                  </Form.Item>
                  <Form.Item
                      label="Гос.номер"
                      name="number"
                      rules={[
                        {
                          required: true,
                          message: "Пожалуйста введите гос.номер"
                        }
                      ]}
                  >
                      <Input />
                  </Form.Item>
                  <Form.Item
                      name="region"
                      label="Регион"
                      rules={[{ required: true, message: "Пожалуйста введите регион" }]}
                  >
                      <Input />
                  </Form.Item>
                  <Form.Item
                      name="brand"
                      label="Марка"
                      rules={[{ required: true, message: "Пожалуйста введите марку" }]}
                  >
                      <Input />
                  </Form.Item>
                  <Row gutter={16} >
                      <Col span={18}>
                        { isCreateNewModel ?
                          <Form.Item
                            label="Модель"
                            name="modelName"
                            rules={[
                              {
                                required: isCreateNewModel,
                                message: "Пожалуйста введите Модель"
                              }
                            ]}>
                            <Input placeholder="Модель" />
                          </Form.Item>
                          : <Form.Item
                            label="Модель"
                            name="modelId"
                            rules={[
                              {
                                required: !isCreateNewModel,
                                message: "Пожалуйста введите Модель"
                              }
                            ]}>
                            <Select
                              showSearch
                              placeholder="Модель"
                              options={transportModels?.response.data.map((type) => ({
                                value: type.id,
                                label: type.name
                              }))}
                              filterOption={(inputValue, option) =>
                                filterSelectOption(inputValue, option?.label || "")
                              }
                            />
                          </Form.Item>}
                      </Col>
                      <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                          <Checkbox checked={isCreateNewModel} onChange={onCreateNewModelChange}>Добавить модель</Checkbox>
                      </Col>
                  </Row>
                  <Form.Item
                      label="Цвет"
                      name="color"
                      rules={[
                        {
                          required: true,
                          message: "Пожалуйста введите цвет"
                        }
                      ]}
                  >
                      <Input type="text" placeholder="Цвет" maxLength={17} />
                  </Form.Item>
                  <Form.Item
                      normalize={(value) => value.toUpperCase()}
                      label="VIN"
                      name="vin"
                      rules={[
                        {
                          required: true,
                          message:
                            "Пожалуйста введите VIN (в случае отсутствия, указать номер шасси)"
                        }
                      ]}
                  >
                      <Input
                          type="text"
                          placeholder="VIN (в случае отсутствия, указать номер шасси)"
                          maxLength={17}
                      />
                  </Form.Item>
                  <Form.Item
                      label="Тип транспорта"
                      name="typeId"
                      rules={[
                        {
                          required: true,
                          message: "Пожалуйста выберите тип транспорта"
                        }
                      ]}
                  >
                      <Select
                          showSearch
                          options={transportTypesForSelect?.map((type) => ({
                            value: type.id,
                            label: type.name
                          }))}
                          filterOption={(inputValue, option) =>
                            filterSelectOption(inputValue, option?.label || "")
                          }
                      />
                  </Form.Item>
                  <Form.Item
                      label="Собственник"
                      name="owner"
                      rules={[
                        {
                          required: true,
                          message: "Пожалуйста введите собственника"
                        }
                      ]}
                  >
                      <Input placeholder="Собственник" />
                  </Form.Item>
                  <Form.Item
                      name="weightWithoutLoad"
                      label="Масса без нагрузки"
                      rules={[
                        {
                          required: true,
                          message: "Пожалуйста введите разрешённую максимальную массу"
                        }
                      ]}
                  >
                      <InputNumber<number>
                          min={0}
                          step={1}
                          style={{ width: "100%" }}
                          formatter={(value) => `${value}кг.`}
                          parser={(value) => Number(value!.replace("кг.", ""))}
                      />
                  </Form.Item>

                  <Form.Item
                      name="permittedMaximumMass"
                      label="Разрешенная максимальная масса"
                      rules={[
                        {
                          required: true,
                          message: "Пожалуйста введите разрешённую максимальную массу"
                        }
                      ]}
                  >
                      <InputNumber<number>
                          min={0}
                          step={1}
                          style={{ width: "100%" }}
                          formatter={(value) => `${value}кг.`}
                          parser={(value) => Number(value!.replace("кг.", ""))}
                      />
                  </Form.Item>

                  <Form.Item
                      name="axlesNumber"
                      label="Количество осей"
                      rules={[
                        {
                          required: true,
                          message: "Пожалуйста введите количество осей"
                        }
                      ]}
                  >
                      <InputNumber
                          placeholder="Количество осей"
                          type={"number"}
                          parser={(value) => parseInt(value || "0", 10)}
                          style={{ width: "100%" }}
                          min={0}
                          step={1}
                      />
                  </Form.Item>
                  <Form.Item
                      hidden={hideTankerFields}
                      label="Тип сертификата"
                      name="certificateTypeId"
                      rules={[
                        {
                          required: !hideTankerFields,
                          message: "Пожалуйста выберите тип "
                        }
                      ]}
                  >
                      <Select
                          onSelect={(_, certificateType: ISelectListItem) => setCertificateType(certificateType)}
                          value={certificateType}
                          options={TransportCertificateTypes}
                      />
                  </Form.Item>
                  <Form.Item
                      hidden={hideTankerFields || (certificateType === undefined)}
                      name="certificateNumber"
                      label={"Номер" + certificateTypeGenitive}
                      rules={[
                        {
                          required: !hideTankerFields,
                          message: "Пожалуйста введите номер" + certificateTypeGenitive
                        }
                      ]}
                  >
                      <Input placeholder="Номер сертификата о калибровке"/>
                  </Form.Item>
                  <Form.Item
                      hidden={hideTankerFields || (certificateType === undefined)}
                      label={"Дата начала действия" + certificateTypeGenitive}
                      name="dateBeginningCertificate"
                      rules={[
                        {
                          required: !hideTankerFields,
                          message:
                            "Пожалуйста введите дату начала действия" + certificateTypeGenitive
                        }
                      ]}
                  >
                      <DatePicker
                          style={{ width: "100%" }}
                          placeholder={"Дата начала действия" + certificateTypeGenitive}
                      />
                  </Form.Item>
                  <Form.Item
                      hidden={hideTankerFields || (certificateType === undefined)}
                      label={"Дата окончания действия" + certificateTypeGenitive}
                      name="dateExpirationCertificate"
                      rules={[
                        {
                          required: !hideTankerFields,
                          message: "Пожалуйста введите дату окончания действия" + certificateTypeGenitive
                        }
                      ]}
                  >
                      <DatePicker
                          style={{ width: "100%" }}
                          placeholder={"Дата окончания действия" + certificateTypeGenitive}
                      />
                  </Form.Item>
                  <Form.Item
                      hidden={ hideTankerFields }
                      name="liter"
                      label="Литраж"
                      rules={[
                        {
                          required: !hideTankerFields,
                          message: "Пожалуйста введите литраж"
                        }
                      ]}
                  >
                      <InputNumber
                          placeholder="Литраж"
                          type={"number"}
                          style={{ width: "100%" }}
                          min={0}
                          step={1}
                      />
                  </Form.Item>
                  <Row gutter={16} hidden={ hideTankerFields }>
                    {Array.from({ length: 8 }, (_, i) => i + 1).map((index) => (
                      <Col key={index} xs={24} md={12} >
                        <Form.Item
                          initialValue={0}
                          name={`section${index}`}
                          label={`Секция ${index}`}
                          rules={[
                            {
                              required: !hideTankerFields,
                              message: `Пожалуйста заполните секцию ${index}`
                            }
                          ]}
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            type="number"
                            min={0}
                          />
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>
              </>
            }
            <Form.Item
            name="stsScan"
            label="Скан СТС "
            rules={[
              {
                required: true,
                message: "Пожалуйста загрузите скан СТС "
              }
            ]}
          >
            <PicturesUpload 
            onChangeHandler={onFileListChange}
            fileList={fileList} />
          </Form.Item>
          </Form>
        </Skeleton>
      </Drawer>
    )
  }
)
