import { FC, Key, ReactNode, useMemo, useState } from "react"

import {
  AlignLeftOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FileExcelFilled,
  FilterFilled,
  PlusSquareOutlined,
  SettingOutlined
} from "@ant-design/icons"
import { Button, Popconfirm, Space, Table as AntTable, TablePaginationConfig, Tooltip, Flex, Card, Typography } from "antd"
import {
  ExpandableConfig,
  FilterValue,
  SortOrder,
  SorterResult, TableCurrentDataSource,
  TableRowSelection
} from "antd/es/table/interface"
import { CheckboxProps } from "antd/lib"

import variables from "@/_styles/variables.module.scss"
import { ColorifiedButton } from "@/components/_Shared/Button/ColorifiedButton"
import { FilterWrapper } from "@/components/_Shared/FilterWrapper"
import { ColumnsSetting } from "@/components/_Shared/Table/ColumnsSetting"
import { Resource, Scope } from "@/constants/permission"

import classes from "./Table.module.scss"

import { usePermission } from "@/hook/usePermission"

import { IFilterProps, ITableColumn } from "@/types/ui"

import { getNested } from "@/utils/getNested"

const { Title } = Typography

interface TablePaginationProps {
  currentPage?: number
  total?: number
  pageSize?: number
  onPageChange?: (page: number, pageSize: number) => void
  onPageSizeChange?: (current: number, size: number) => void
  showSizeChanger?: boolean
}

interface TableDownloadProps {
  onDownload: () => void
  isLoading: boolean
}

interface TablePermissionProps {
  resource: Resource
}

type ScrollConfig = {
  index?: number
  key?: Key
  top?: number
}
type OnCustomizeScroll = (info: {
  currentTarget?: HTMLElement
  scrollLeft?: number
}) => void

type CustomizeComponent = FC<any>
type CustomizeScrollBody<T> = (data: readonly T[], info: {
  scrollbarSize: number
  ref: React.Ref<{
      scrollLeft: number
      scrollTo?: (scrollConfig: ScrollConfig) => void
  }>
  onScroll: OnCustomizeScroll
}) => React.ReactNode

interface TableComponents<T> {
  table?: CustomizeComponent
  header?: {
      wrapper?: CustomizeComponent
      row?: CustomizeComponent
      cell?: CustomizeComponent
  }
  body?: CustomizeScrollBody<T> | {
      wrapper?: CustomizeComponent
      row?: CustomizeComponent
      cell?: CustomizeComponent
  }
}

type SizeType = "small" | "middle" | "large" | undefined

interface TableProps<T> {
  tableTitle?: string,
  tableTitleIcon?: ReactNode,
  dataSource?: T[]
  columns: ITableColumn<T>[]
  onAdd?: () => void
  addText?: string
  onEdit?: (id: any) => void
  onCopy?: (selectedRowKeys: Key[]) => void
  onRemove?: (selectedRowKeys: Key[]) => void
  download?: TableDownloadProps
  onAction?: (selectedRowKeys: Key[]) => void
  actionText?: string
  isActionLoading?: boolean
  isLoading?: boolean
  rowKey: string | string[]
  onRowClick?: (data: T, index: number | undefined) => void
  onUpdateColumns?: (newColumns: ITableColumn<T>[]) => void
  onSorterChange?: (value: string) => void
  pagination?: TablePaginationProps 
  isPagination?: boolean
  filters?: IFilterProps
  permission?: TablePermissionProps
  isSelectable?: boolean
  selectionCheckboxProps?: ((record: T) => Partial<Omit<CheckboxProps, "checked" | "defaultChecked">>) | undefined
  bordered?: boolean
  expandable?: ExpandableConfig<T>
  components?: TableComponents<T>
  footer?: (data: readonly T[]) => React.ReactNode
  summary?:(data: readonly T[]) => React.ReactNode,
  sortDirections?: SortOrder[],
  rowClassName?: (record: T, index: number | undefined) => string,
  className?: string,
  size?: SizeType,
  disabledCreateButton?: boolean
}

export const Table = <T extends Object>(props: TableProps<T>) => {
  const {
    tableTitle = "",
    tableTitleIcon,
    dataSource = [],
    columns,
    pagination,
    isPagination = true,
    onCopy,
    onRemove,
    onEdit,
    onAdd,
    onAction,
    addText = "Добавить",
    actionText,
    download,
    isLoading,
    isActionLoading,
    rowKey,
    onRowClick,
    onUpdateColumns,
    onSorterChange,
    filters,
    permission,
    isSelectable,
    selectionCheckboxProps,
    bordered,
    expandable,
    components,
    footer,
    summary,
    sortDirections,
    rowClassName,
    className,
    size,
    disabledCreateButton
  } = props

  const {
    showSizeChanger,
    currentPage,
    pageSize,
    total,
    onPageChange,
    onPageSizeChange
  } = pagination || {}

  const visibleColumns = columns.filter((column) => column.isVisible)

  const { hasPermission } = usePermission()

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [openFilters, setOpenFilters] = useState<boolean>(false)
  const [openSetting, setOpenSetting] = useState<boolean>(false)

  const onOpenFiltersHandler = () => {
    setOpenFilters(true)
  }

  const onOpenSettingHandler = () => {
    setOpenSetting(true)
  }

  const onCloseSettingHandler = () => {
    setOpenSetting(false)
  }

  const onSelectHandler = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const onCopyHandler = () => {
    if (selectedRowKeys.length && onCopy) {
      onCopy(selectedRowKeys)
    }
  }

  const onRemoveHandler = async () => {
    if (selectedRowKeys.length && onRemove) {
      await onRemove(selectedRowKeys)
      setSelectedRowKeys([])
    }
  }

  const onEditHandler = () => {
    if(selectedRowKeys.length === 1 && onEdit) {
      const id = selectedRowKeys[0] as string
      onEdit(id)
    }
  }

  const onCreateHandler = () => {
    if (onAdd) {
      onAdd()
    }
  }

  const onActionHandler = async () => {
    if (selectedRowKeys.length && onAction) {
      await onAction(selectedRowKeys)
    }
  }

  const rowSelection: TableRowSelection<T> = {
    selectedRowKeys,
    onChange: onSelectHandler,
    getCheckboxProps: selectionCheckboxProps
  }

  const selection = isSelectable === false ? {} : {
    rowSelection: rowSelection
  }

  const onRow = (data: T, index?: number) => {
    return {
      onClick: (event: any) => {
        const rowCellWithLinkOrBtn = event.target.href !== undefined
          || event.target.type === "button"
          || event.target.parentElement.type === "button"
        return onRowClick && !rowCellWithLinkOrBtn ? onRowClick(data, index) : null
      }
    }
  }

  const onRowClassName = (data: T, index?: number): string => {
    return rowClassName ? `${classes.row} ${rowClassName(data, index)}` : `${classes.row}`
  }
  

  const onUpdatedColumnsHandler = (updatedColumns: ITableColumn<T>[]) => {
    if (onUpdateColumns) {
      onUpdateColumns(updatedColumns)
      setOpenSetting(false)
    }
  }

  const generateRowKey = (data: T): string => {
    if (Array.isArray(rowKey)) {
      return getNested(data, rowKey) || ""
    }

    return data[rowKey as keyof object]
  }

  const paginationProps: TablePaginationConfig = {
    position: ["bottomCenter"],
    current: currentPage,
    total,
    pageSize,
    showSizeChanger,
    onChange: onPageChange,
    onShowSizeChange: onPageSizeChange,
    showTotal: (total, range) => {return  <span>{range[0]}-{range[1]} из {total}</span>},
    showQuickJumper: true
  }

  const isCan = useMemo(() => {
    if(permission?.resource == null) return {
      Create: true,
      Update: true,
      Delete: true,
      Actions: true
    }

    return {
      Create: hasPermission(permission.resource, Scope.Create),
      Update: hasPermission(permission.resource, Scope.Update),
      Delete: hasPermission(permission.resource, Scope.Delete),
      Actions: hasPermission(permission.resource, Scope.Actions)
    }
  }, [hasPermission, permission])

  const showTableTitle = useMemo((): boolean => {
    let topContents: boolean = false
    let actionsContents: boolean = false

    topContents = !!(tableTitle || onUpdateColumns || download || filters)
    actionsContents = !!((isCan.Create && addText && onAdd)
    || (isCan.Update && onEdit)
    || (isCan.Delete && onRemove)
    || (isCan.Create && onCopy)
    || (isCan.Actions && actionText && onAction))

    return !!(topContents || actionsContents)
  }, [isCan, tableTitle])

  const onTableChange = (
    _: TablePaginationConfig,
    __: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
    ___: TableCurrentDataSource<T>
  ) => {
    if (!onSorterChange) {
      return
    }


    if (!Array.isArray(sorter)) {
      if (!sorter.order) {
        onSorterChange("")
      } else {
        const order = sorter.order === "ascend" ? "asc" : "desc"

        const newOrderBy = `${(sorter.column as ITableColumn<T>)?.sorterField || sorter.columnKey} ${order}`
        onSorterChange(newOrderBy)
      }
    }
  }

  return (
    <>
    <Card hidden={!showTableTitle} className={classes.titleCard} bordered={false}
        title={tableTitle && (
          <Title level={3} className={classes.titleText}>
            <Space size={12}>
              <div style={{ color: variables.basecolor }}>{tableTitleIcon ?? <AlignLeftOutlined />}</div>
              {tableTitle}
            </Space>
          </Title>
        )}
        extra={<Space>
          {onUpdateColumns && (
            <Tooltip title="Настройка полей">
              <Button
                color="primary" variant="outlined"
                icon={<SettingOutlined />}
                onClick={onOpenSettingHandler}
              />
            </Tooltip>
          )}
          {
            download && (
              <Tooltip title="Выгрузить в Excel">
                <Button
                  color="primary" variant="outlined"
                  disabled={download.isLoading || isActionLoading}
                  onClick={download.onDownload}
                  icon={<FileExcelFilled />}>
                  Экспорт XLS
                </Button>
              </Tooltip>
            )
          }
          {filters && (
            <Tooltip title="Фильтр">
              <Button
                type="primary"
                icon={<FilterFilled />}
                onClick={onOpenFiltersHandler}>Фильтр</Button>
            </Tooltip>
          )}
        </Space>}
    >
      {/* <Flex justify="space-between">
        <Space>
          {
            tableTitle && (
              <Title level={3} className={classes.titleText}>
                <Space size={12}>
                  <div style={{ color: variables.basecolor }}>{tableTitleIcon}</div>
                  {tableTitle}
                </Space>
              </Title>
            )
          }
        </Space>
        <Space>
          {onUpdateColumns && (
            <Tooltip title="Настройка полей">
              <Button
                color="primary" variant="outlined"
                icon={<SettingOutlined />}
                onClick={onOpenSettingHandler}
              />
            </Tooltip>
          )}
          {
            download && (
              <Tooltip title="Выгрузить в Excel">
                <Button
                  color="primary" variant="outlined"
                  disabled={download.isLoading || isActionLoading}
                  onClick={download.onDownload}
                  icon={<FileExcelFilled />}>
                    Экспорт XLS
                  </Button>
              </Tooltip>
            )
          }
          {filters && (
            <Tooltip title="Фильтр">
              <Button
                type="primary"
                icon={<FilterFilled />}
                onClick={onOpenFiltersHandler}>Фильтр</Button>
            </Tooltip>
          )}
        </Space>
      </Flex> */}
      <Flex>
      <Space>
          {
            isCan.Create && addText && onAdd && (
              <ColorifiedButton
                themeColor="orange"
                disabled={disabledCreateButton}
                onClick={onCreateHandler}
                type="primary"
                icon={<PlusSquareOutlined />}>
                {addText}
              </ColorifiedButton>
            )
          }
          {
            isCan.Update && onEdit && (
              <Tooltip title="Редактировать">
                <Button disabled={selectedRowKeys.length !== 1 || isActionLoading} onClick={onEditHandler} color="primary" variant="dashed" icon={<EditOutlined />} />
              </Tooltip>
            )
          }
          {
            isCan.Delete && onRemove && (
              <Popconfirm disabled={!selectedRowKeys.length || isActionLoading}
                          onConfirm={onRemoveHandler}
                          title="Удалить"
                          description="Вы действительно хотите удалить?"
                          okText="Да"
                          cancelText="Нет">
                <Tooltip title="Удалить">
                  <Button
                    disabled={!selectedRowKeys.length || isActionLoading}
                    color="primary" variant="dashed"
                    icon={<DeleteOutlined/>}/>
                </Tooltip>
              </Popconfirm>

            )
          }
          {
            isCan.Create && onCopy && (
              <Tooltip title="Скопировать">
                <Button
                  disabled={selectedRowKeys.length !== 1 || isActionLoading}
                  onClick={onCopyHandler}
                  color="primary" variant="dashed"
                  icon={<CopyOutlined/>}/>
              </Tooltip>
            )
          }
          {
              isCan.Actions && actionText && onAction && (
                  <Button
                      disabled={selectedRowKeys.length === 0 || isActionLoading}
                      onClick={onActionHandler}
                      type="primary">
                    {actionText}
                  </Button>
              )
          }
        </Space>
        </Flex>
      </Card>

      <AntTable
        size={size}
        components={components}
        locale={{ emptyText: "Нет данных" }}
        columns={visibleColumns}
        dataSource={dataSource}
        showSorterTooltip={false}
        bordered={bordered}
        {...selection}
        rowKey={generateRowKey}
        loading={isLoading}
        onRow={onRow}
        rowClassName={onRowClassName}
        pagination={isPagination ? { ...paginationProps, showSizeChanger: !!onPageSizeChange } : false}
        onChange={onTableChange}
        expandable={expandable}
        footer={footer}
        summary={summary}
        sortDirections={sortDirections}
        className={className}
      />
      {onUpdateColumns && (
        <ColumnsSetting
          open={openSetting}
          onCancel={onCloseSettingHandler}
          columns={columns}
          onSubmit={onUpdatedColumnsHandler}
        />
      )}
      {filters && (
        <FilterWrapper
          filterKey={filters.filterKey}
          open={openFilters}
          setOpen={setOpenFilters}
          filters={filters.filters}
          submitText={filters.submitText}
          cancelText={filters.cancelText}
          onSubmit={filters.onSubmit}
          onCancel={filters.onCancel}
        />
      )}
    </>
  )
}
