import { forwardRef, useContext, useEffect, useMemo, useState } from "react"

import { UserOutlined, DownOutlined } from "@ant-design/icons"
import { Form, Space, MenuProps, Dropdown, Button } from "antd"

import { Resource, Scope } from "@/constants/permission"
import { CustomerContext } from "@/context/CustomerContext"

import { useCustomerContextQuery } from "@/hook/Dictionaries/Customers/useCustomerQuery"
import { usePermission } from "@/hook/usePermission"

export interface CustomerSwapperRef {}

export interface CustomerSwapperProps {
  collapsed: boolean
  setCurrentCustomerName: (name:string) => void
}

interface CustomerSwapperFormValues {
  currentCustomerId: number
}

export const CustomerSwapper = forwardRef<
  CustomerSwapperRef,
  CustomerSwapperProps
>((props, ref) => {
  const { collapsed, setCurrentCustomerName } = props
  const [form] = Form.useForm<CustomerSwapperFormValues>()
  const { data, isLoading } = useCustomerContextQuery()
  const [customerItems, setCistomerItems] = useState<MenuProps["items"]>([])
  const context = useContext(CustomerContext)
  const { hasPermission } = usePermission()

  useEffect(() => {
    if (data?.response.data == undefined || !hasPermission(Resource.Account, Scope.Menu)) return

    const currentCustomerId = Number(sessionStorage.getItem("currentCustomerId"))

    if (currentCustomerId) {
      form.setFieldValue("currentCustomerId", currentCustomerId)
      context.setCustomer(
        data?.response.data.find((c) => c.id == currentCustomerId)
      )
      setCurrentCustomerName(data?.response.data.find((c) => c.id == currentCustomerId)?.name ?? "")
    } else {
      if (data.response.data.length != 0) {
        form.setFieldValue("currentCustomerId", data.response.data[0].id)
        context.setCustomer(data.response.data[0])
        sessionStorage.setItem(
          "currentCustomerId",
          data.response.data[0].id.toString()
        )
        setCurrentCustomerName(data?.response.data.find((c) => c.id == data.response.data[0].id)?.name ?? "")

      }
    }

    const dropDwonCustomers: MenuProps["items"] = []
    customers.forEach(c=>{
      dropDwonCustomers.push({
        key: c.value,
        label: c.label,
        icon: <UserOutlined />
      })
    })

    setCistomerItems(dropDwonCustomers)
  }, [data?.response.data])

  const customers = useMemo(() => {
    if (data?.response.data == undefined) return []

    return data.response.data.map((customer) => ({
      label: customer.name,
      value: customer.id
    })).sort((a,b) => a.value - b.value)
  }, [data?.response.data])

  const clientName = useMemo(() => {
    if (data?.response.data == undefined || data?.response.data.length == 0)
      return ""

    return data.response.data[0].customerClientName
  }, [data?.response.data])

  const onActionHandler: MenuProps["onClick"] = async (e) => {
    let key = Number(e.key)
    customerSwap(key)
  }

  const customerSwap = (customerId:number) => {
    context.setCustomer(data?.response.data.find((c) => c.id == customerId))
    sessionStorage.setItem("currentCustomerId", customerId.toString())
    setCurrentCustomerName(customers.find(c=>c.value == customerId)?.label ?? "")
  }

  return (
    <>
    <Space hidden={collapsed} align="center">
      <Dropdown menu={{ items: customerItems, onClick: onActionHandler }}>
        <Button type="default" ghost={true} shape="round" size="small">
          <Space>
            {clientName}
            <DownOutlined  style={{ fontSize: "10px" }}/>
          </Space>
        </Button>
      </Dropdown>
    </Space>
    </>
  )
})
