export interface IFuelLimit {
  id: number
  startDate: Date
  endDate: Date
  actualLimit: number
  quantityOrdered: number
  commentary?: string
  fuelId: number
  fuelTypeName: string
  fuelDepotName: string
  limitDaysCount: number
}

export interface IFuelLimitCreate {
  startDate: Date | string
  endDate: Date | string
  limit: number
  fuelId: number
  commentary?: string
}

export interface IFuelLimitUpdate extends IFuelLimitCreate {
  id: number
}

export interface IFuelLimitCategorySettingRequest {
  id?: number
  fuelPeriodLimitId: number
  categoryId: number
  categoryLimit: number
  isGrouped: boolean
}

export interface IFuelLimitCard {
  id: number
  startDate: Date | string
  endDate: Date | string
  initialLimit: number
  actualLimit: number
  quantityOrdered: number
  commentary?: string
  fuelId: number
  fuelTypeName: string
  fuelDepotName: string
  limitDaysCount: number
  periodLimitCategorySettings: IFuelLimitCategorySetting[]
}

export interface IFuelLimitCategorySetting {
  id?: number
  fuelPeriodLimitId: number
  categoryId: number
  categoryName: string
  categoryPercent: number
  isDefault: boolean
  categoryLimit: number
  freeLimit: number
  ownOrderedLimit: number
  orderedLimit: number
  isGrouped: boolean
  isGroupHead: boolean
}

export interface IFuelLimitCategorySettingTotals {
  categoryPercentSum: number
  categoryLimitSum: number
  freeLimitSum: number
  orderedLimitSum: number
}
export const FUEL_LIMITS_KEY = "FUEL_LIMITS_KEY"
