import { Flex, Form, Segmented, Select } from "antd"
import dayjs, { Dayjs } from "dayjs"

import { CustomRangePicker } from "@/components/_Shared/CustomRangePicker"

import classes from "../AnalyticsSettings.module.scss"

import { IPagingResponse } from "@/types/api"
import { IDepot } from "@/types/IDepot"
import { IFuelType } from "@/types/IFuelType"
import { Period } from "@/types/IOrderReport"


export interface CustomerAnalyticsSettingsProps {
  fuelTypes: IPagingResponse<IFuelType> | undefined
  depots: IDepot[] | undefined
  options: CustomerAnalyticsSettingsOptions
  onRangeChange: ((dates: [start: Dayjs | null, end: Dayjs | null] | null, dateStrings: [string, string]) => void) | undefined
  onPeriodChange: ((period: Period) => void) | undefined
  onChosenFuelTypeChange: ((fuelTypeId: number | undefined) => void) | undefined
  onChosenDepotChange: ((depotId: number | undefined) => void) | undefined
  isComparison: boolean
}

export interface CustomerAnalyticsSettingsOptions {
  depotId: number | undefined
  fuelTypeId: number | undefined
  startDate: string | undefined
  endDate: string | undefined
  activePeriod: Period | undefined
}

const periods = [
  {
    label: "Год",
    value: Period.Year
  },
  {
    label: "Квартал",
    value: Period.Quarter
  },
  {
    label: "Месяц",
    value: Period.Month
  },
  {
    label: "Неделя",
    value: Period.Week
  },
  {
    label: "День",
    value: Period.Day
  }
]

const CustomerAnalyticsSettings = (props: CustomerAnalyticsSettingsProps) => {
  const {
    fuelTypes,
    options,
    depots,
    onRangeChange,
    onPeriodChange,
    onChosenFuelTypeChange,
    onChosenDepotChange,
    isComparison
  } = props

  const {
    startDate,
    endDate,
    activePeriod
  } = options

  return (
    <Flex vertical>
      <Flex gap="middle" className={classes["period-picker-row"]}>
        <CustomRangePicker
          value={[startDate ? dayjs(startDate) : undefined, endDate ? dayjs(endDate) : undefined]}
          onChange={onRangeChange}
        />
        <Segmented
          options={periods}
          value={activePeriod}
          onChange={onPeriodChange}
        />
      </Flex>
      <Flex gap="middle" align="baseline">
        <Form.Item
          label="Вид топлива">
          <Select
            onClear={() => onChosenFuelTypeChange && onChosenFuelTypeChange(undefined)}
            onChange={onChosenFuelTypeChange}
            value={options.fuelTypeId}
            placeholder="Вид топлива"
            showSearch
            allowClear
            options={fuelTypes?.response.data.map(d => ({
              value: d.id,
              label: d.name
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Нефтебаза">
          <Select
            onClear={() => onChosenDepotChange && onChosenDepotChange(undefined)}
            onChange={onChosenDepotChange}
            value={options.depotId}
            placeholder="Нефтебаза"
            showSearch
            allowClear
            options={depots?.map(d => ({
              value: d.id,
              label: d.name
            }))}
          />
        </Form.Item>
      </Flex>
    </Flex>
  )
}

export default CustomerAnalyticsSettings