import { FC, useEffect, useRef, useState } from "react"

import { EditOutlined } from "@ant-design/icons"
import { Button, Card, Checkbox, Descriptions, DescriptionsProps, Empty, Skeleton, Space, Tag, Tooltip } from "antd"
import { useNavigate, useParams } from "react-router-dom"

import classes from "@/_styles/cardById.module.scss"
import { FuelLimitAddForm, FuelLimitAddFormProps } from "@/components/Fuel/FuelLimitAddForm"
import { FuelLimitSettingsTable } from "@/components/Fuel/FuelLimitSettingsTable"
import { Resource, Scope } from "@/constants/permission"

import { useFuelLimitCardById } from "@/hook/FuelLimits/useFuelLimitsQuery"
import { usePermission } from "@/hook/usePermission"

import { IFuelLimitCard } from "@/types/IFuelLimit"

import { paths } from "@/router/paths"

import { formatDate } from "@/utils/formatDate"
import { formatNulableValue } from "@/utils/formatString"


const FuelLimitById: FC = () => {
    const navigate = useNavigate()
    const { hasPermission } = usePermission()
    const fuelLimitAddFormComponent = useRef<FuelLimitAddFormProps>(null)

    const { id } = useParams<{ id?: string }>()

    const { data: fuelLimit, isLoading: fuelLimitLoading, isError: fuelLimitLoadError, refetch } = useFuelLimitCardById(Number(id))

    const [editRow, setEditRow] = useState<boolean>(false)
    const [fuelLimitData, setFuelLimitData] = useState<IFuelLimitCard | null>(null)
    const [fuelLimitDescriptions, setFuelLimitDescriptions] = useState<DescriptionsProps["items"]>([])

    const onBackHandler = () => {
        navigate(paths.fuelLimits)
    }

    const onEditHandler = () => {
        fuelLimitAddFormComponent.current?.open(Number(id))
    }

    useEffect(()=> {
        if(fuelLimit) {
            setFuelLimitData(fuelLimit.response)

            setFuelLimitDescriptions([
                {
                    key: "0",
                    label: "С",
                    children: formatDate(fuelLimit.response.startDate)
                },
                {
                    key: "1",
                    label: "По",
                    children: formatDate(fuelLimit.response.endDate)
                },
                {
                    key: "2",
                    label: "Начальный лимит",
                    children: fuelLimit.response.initialLimit
                }, {
                    key: "3",
                    label: "Актуальный лимит",
                    children: fuelLimit.response.actualLimit
                },
                {
                    key: "4",
                    label: "Комментарий",
                    children: formatNulableValue(fuelLimit.response.commentary)
                }
            ])

            setEditRow(fuelLimit.response.periodLimitCategorySettings?.filter(s=> !s.isDefault).length > 0)
        }
    }, [fuelLimit])

    const onCloseEditFormHandler = () => {
        refetch()
    }

    const titleNode = (
        <>
        <Space>
            Лимит на топливо: {fuelLimit?.response.fuelDepotName} - {fuelLimit?.response.fuelTypeName}
            <Tag color="blue">{fuelLimitData?.limitDaysCount + " дней(я)"}</Tag>
            {hasPermission(Resource.Fuel, Scope.Update) && <Tooltip title="Редактировать">
                <Button type="dashed" icon={<EditOutlined />} onClick={onEditHandler} />
            </Tooltip>
            }
        </Space>
        </>
    )

    if (fuelLimitLoadError) {
        return <Empty description="Данные по лимитам не найдены" />
    }
      
    return (
        <>
        <FuelLimitAddForm ref={fuelLimitAddFormComponent} handleClose={onCloseEditFormHandler} />
        <Skeleton loading={fuelLimitLoading}>
            {fuelLimit && fuelLimitData && (
            <>
                <Card title={titleNode} bordered>
                <Space direction="vertical" style={{ display: "flex" }}>
                    <Descriptions title="" bordered items={fuelLimitDescriptions} column={3} />
                    <Card title="Настройки по категориям">
                        <FuelLimitSettingsTable key={new Date().getTime()}
                            editable={editRow}
                            settings={fuelLimitData.periodLimitCategorySettings}
                            fuelLimit={fuelLimitData}
                            refetchCallback={refetch}></FuelLimitSettingsTable>
                    </Card>
                    </Space>
                </Card>
                <Space className={classes.buttons}>
                    <Button onClick={onBackHandler}>Назад к списку</Button>
                </Space>
            </>
            )}
        </Skeleton>
        </>
    )
}

export default FuelLimitById
