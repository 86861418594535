import { NavLink } from "react-router-dom"

import { ICustomer } from "@/types/ICustomer"
import { ITableColumn } from "@/types/ui"

import { paths } from "@/router/paths"


export const customerColumns: ITableColumn<ICustomer>[] = [
  {
    title: "Код контрагента",
    key: "id",
    dataIndex: ["id"],
    align: "center",
    sorter: true,
    isVisible: false,
    order: 1
  },
  {
    title: "Клиент",
    key: "customerClientName",
    dataIndex: ["customerClientName"],
    align: "center",
    sorter: true,
    isVisible: true,
    order: 1
  },
  {
    title: "Контрагент",
    key: "name",
    dataIndex: ["name"],
    align: "center",
    //render: (_, customer: ICustomer) => <NavLink to={paths.customerById(customer.id)}>{customer.name}</NavLink>,
    sorter: true,
    isVisible: true,
    order: 2
  },
  {
    title: "Баланс",
    key: "balance",
    dataIndex: ["balance"],
    align: "center",
    sorter: true,
    isVisible: false,
    order: 3
  },
  {
    title: "Сумма заказов в работе",
    key: "sumOrder",
    dataIndex: ["sumOrder"],
    align: "center",
    sorter: true,
    isVisible: false,
    order: 4
  },
  {
    title: "Сумма доступная для заказа",
    key: "availableSum",
    dataIndex: ["availableSum"],
    align: "center",
    sorter: true,
    isVisible: false,
    order: 5
  },
  {
    title: "Дневной лимит",
    key: "dayLimit",
    dataIndex: ["dayLimit"],
    align: "center",
    sorter: true,
    isVisible: false,
    order: 6
  },
  {
    title: "Телефон",
    key: "phone",
    dataIndex: ["phone"],
    align: "center",
    render: (_, customer: ICustomer) => customer.phone ? `+7 ${customer.phone}` : "-",
    sorter: true,
    isVisible: true,
    order: 7
  },
  {
    title: "ИНН",
    key: "inn",
    dataIndex: ["inn"],
    align: "center",
    sorter: true,
    isVisible: true,
    order: 8
  },
  {
    title: "ОГРН",
    key: "ogrn",
    dataIndex: ["ogrn"],
    align: "center",
    sorter: true,
    isVisible: true,
    order: 9
  }
  // {
  //   title: "Пользователи",
  //   key: "customerUsers",
  //   dataIndex: ["dayLimit"],
  //   align: "center",
  //   sorter: false,
  //   isVisible: true,
  //   order: 10
  // }
]
