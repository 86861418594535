import { FC, Key, useRef } from "react"

import { Table as AntTable } from "antd"
import { useNavigate, useSearchParams } from "react-router-dom"

import { Table } from "@/components/_Shared/Table"
import {
  CustomerCategoryAddForm,
  CustomerCategoryAddFormProps
} from "@/components/CustomerCategories/CustomerCategoryAddForm"
import { Resource } from "@/constants/permission"
import { getCustomerCategoryExcel } from "@/http/customerCategory"
import { customerCategoryColumns } from "@/pages/CustomerCategory/CustomerCategoryColumns"

import { useCustomerCategoryFilters } from "./CustomerCategoryFilters"

import { useCustomerCategoryMutation } from "@/hook/Dictionaries/Customers/useCustomerCategoryMutation"
import { useCustomerCategoryListQuery, useCustomerCategoryPercentSumQuery } from "@/hook/Dictionaries/Customers/useCustomerCategoryQuery"
import { useDownloadFile } from "@/hook/useDownloadFile"
import { useLocationSearchHandler } from "@/hook/useLocationSearchHandler"
import { useTable } from "@/hook/useTable"

import {
  CUSTOMER_CATEGORIES_KEY,
  ICustomerCategory
} from "@/types/ICustomerCategory"

import { paths } from "@/router/paths"

import { formatNumber } from "@/utils/formatNumber"
import { getInitialColumns } from "@/utils/getInitialColumns"

const CustomerCategoryPage: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const customerCategoryComponent = useRef<CustomerCategoryAddFormProps>(null)

  const { download: excelDownload, isLoading: isExcelLoading } =
    useDownloadFile(() =>
      getCustomerCategoryExcel(
        {
          Filter: filter.filter,
          OrderBy: order.orderBy
        },
        getInitialColumns(customerCategoryColumns, CUSTOMER_CATEGORIES_KEY)
      )
    )

  const { deleteMutation } = useCustomerCategoryMutation()

  const { columns, pagination, filter, order } = useTable({
    initialColumns: getInitialColumns(customerCategoryColumns, CUSTOMER_CATEGORIES_KEY),
    key: CUSTOMER_CATEGORIES_KEY
  })

  const { data, isLoading } = useCustomerCategoryListQuery({
    Page: pagination.page,
    PageSize: pagination.pageSize,
    Filter: filter.filter,
    OrderBy: order.orderBy
  })
  const { data: percentSum, refetch: refetchSum } = useCustomerCategoryPercentSumQuery()

  const { filters } = useCustomerCategoryFilters()

  const onAddHandler = () => {
    customerCategoryComponent.current?.open()
  }

  const onEditHandler = (id: number) => {
    customerCategoryComponent.current?.open(id)
  }

  const onRemoveHandler = async (ids: Key[]) => {
    await deleteMutation.mutateAsync(ids.map((id) => Number(id)))
  }

  const onOpenForEdit = () => {
    const searchId = searchParams.get("edit")
    if (searchId) {
      onEditHandler(Number(searchId))
    }
  }

  useLocationSearchHandler({
    value: "edit",
    handler: onOpenForEdit
  })

  useLocationSearchHandler({
    value: "openForm",
    handler: onAddHandler
  })

  const onExcelDownloadHandler = async () => {
    await excelDownload()
  }

  const onRowClickHandler = (data: ICustomerCategory) => {
    navigate(paths.customerCategoryById(data.id))
  }

  return (
    <>
      <CustomerCategoryAddForm ref={customerCategoryComponent} handleClose={refetchSum} />
      <Table
        tableTitle="Категории клиентов"
        dataSource={data?.response.data}
        columns={columns.data}
        rowKey="id"
        isLoading={isLoading}
        onUpdateColumns={columns.onColumnChange}
        pagination={{
          currentPage: pagination.page,
          pageSize: pagination.pageSize,
          total: data?.response.count,
          onPageChange: pagination.onPageChange,
          onPageSizeChange: pagination.onPageSizeChange
        }}
        filters={{
          filters: filters,
          onSubmit: filter.onFilterChange,
          onCancel: filter.onFilterReset,
          filterKey: CUSTOMER_CATEGORIES_KEY
        }}
        download={{
          onDownload: onExcelDownloadHandler,
          isLoading: isExcelLoading
        }}
        onAdd={onAddHandler}
        onEdit={onEditHandler}
        onRemove={onRemoveHandler}
        onRowClick={onRowClickHandler}
        addText="Добавить"
        onSorterChange={order.setOrderBy}
        permission={{
          resource: Resource.CustomerCategory
        }}
        summary={() => {
          return (
              <AntTable.Summary fixed={"bottom"}>
                <AntTable.Summary.Row>
                  <AntTable.Summary.Cell index={0} colSpan={3}>Суммарно по всем категориям:</AntTable.Summary.Cell>
                  <AntTable.Summary.Cell index={1} align="center">
                    <strong style={{ color: (Number(percentSum?.response) > 100 ? "#ff4d4f" : "") }}>{formatNumber(Number(percentSum?.response))} %</strong>
                  </AntTable.Summary.Cell>
                </AntTable.Summary.Row>
              </AntTable.Summary>
            )
          }
        }
      />
    </>
  )
}

export default CustomerCategoryPage
