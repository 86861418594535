import { Button, ButtonProps, ConfigProvider } from "antd"

import variables from "@/_styles/variables.module.scss"

interface CustomButtonProps extends ButtonProps {
    themeColor?: "blue" | "orange" | "green" | "gray";
}

export const ColorifiedButton = (props: CustomButtonProps) => {

    let themeColor: string

    switch(props.themeColor){
        case "green":
            themeColor = variables.btnGreenColor
            break
        case "orange":
            themeColor = variables.btnOrangeColor
            break
        case "gray":
            themeColor = variables.btnGrayColor
            break
        case "blue":
        default:
            themeColor = variables.basecolor
            break
    }

    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: themeColor
                    }
                }}
            >
                <Button
                    type={props.type}
                    icon={props.icon}
                    disabled={props.disabled}
                    shape={props.shape}
                    block={props.block}
                    loading={props.loading}
                    hidden={props.hidden}
                    danger={props.danger}
                    className={props.className}
                    styles={props.styles}
                    style={props.style}
                    ghost={props.ghost}
                    onClick={props.onClick}
                    onMouseDown={props.onMouseDown}
                >
                    {props.children}
                </Button>
            </ConfigProvider>
        </>
    )
}