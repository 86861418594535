import React, { FC, useContext, useEffect, useRef, useState } from "react"

import { Button, Card, Descriptions, Empty, Skeleton, Space, Tag, UploadFile } from "antd"
import { Link, useNavigate, useParams } from "react-router-dom"
import { uid } from "uid"

import { PicturesUpload } from "@/components/_Shared/PicturesUpload"
import { TransportAddForm, TransportAddFormProps } from "@/components/Transport/TransportAddForm"
import { Resource, Scope } from "@/constants/permission"
import { CustomerContext } from "@/context/CustomerContext"
import { useNotificationContext } from "@/context/NotificationContext"

import classes from "./TransportById.module.scss"

import { useTransportByIdQuery } from "@/hook/Dictionaries/useTransportQuery"
import { useTransportsMutation } from "@/hook/Dictionaries/useTransportsMutation"
import { usePermission } from "@/hook/usePermission"

import {
  ITransportCertificateType,
  ITransportChangeVerificationStatus,
  ITransportClass,
  TransportCertificateTypesGenitives,
  VerificationStatus
} from "@/types/ITransport"

import { paths } from "@/router/paths"

import { uploadFileConvert } from "@/utils/uploadFileConvert"

const TransportById: FC = () => {
  const customerContext = useContext(CustomerContext)

  const transportComponent = useRef<TransportAddFormProps>(null)
  const navigate = useNavigate()
  const { id } = useParams<{ id?: string }>()
  const { hasPermission } = usePermission()
  const { changeVerificationMutation } = useTransportsMutation()
  const { refetchCountAwaitsVerification } =useNotificationContext()

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const { data, isLoading, isError } = useTransportByIdQuery(Number(id))

  const showAdditionalData = data?.response.class !== ITransportClass.Truck

  useEffect(() => {
    if (data?.response?.stsScans) {
      const newPromises = data.response.stsScans.map((file) =>
        uploadFileConvert({
          url: file.content,
          name: file.name,
          uid: uid()
        })
      )
      Promise.all(newPromises).then((res) => {
        onFileListChange(res)
      })
    }
  }, [data?.response.stsScans])

  const onFileListChange = (file: UploadFile | UploadFile[]) => {
    if (Array.isArray(file)) {
      setFileList(file)
    } else {
      setFileList((prevState) => [...prevState, file])
    }
  }

  const onBackHandler = () => {
    navigate(paths.transport)
  }

  const confirmVerification = async () => {
    const reqeust: ITransportChangeVerificationStatus = {
      id : data!.response.id,
      status : VerificationStatus.Confirmed
    }
    await changeVerificationMutation.mutateAsync(reqeust)
    refetchCountAwaitsVerification()
  }

  const rejectVerification = async () =>{
    const request: ITransportChangeVerificationStatus ={
      id: data!.response.id,
      status: VerificationStatus.Rejected
    } 

    await changeVerificationMutation.mutateAsync(request)
    refetchCountAwaitsVerification()
  }

  const openCustomerEdit = () => {
    transportComponent.current?.open(Number(id), true)
  }

  if (isError) {
    return <Empty description="Данные Транспорта не найдены" />
  }

  const certificateTypeGenitive = ((data?.response.certificateType !== undefined) && (data.response.certificateType !== null))
    ? " " + TransportCertificateTypesGenitives[data.response.certificateType]
    : ""

  const certificateTypeDative = ((data?.response.certificateType !== undefined) && (data.response.certificateType !== null))
  ? data.response.certificateType === ITransportCertificateType.Calibration
    ? " сертификату о калибровке" : " свидетельству о поверке" : ""
  
  return (
    <>
      <TransportAddForm ref={transportComponent}/>
      <Skeleton loading={isLoading}>
        {data && (
          <>
            <Descriptions
              title={
                <div className={classes["header"]}>
                  <span>Транспорт {data.response.number}</span>
                  <div className={classes["header"]}>
                    {!customerContext.isCustomer() &&
                      data.response.clients.map((client) => {
                        return (
                          <Tag>
                            <Link to={paths.customerClientById(client.id)}>
                              {client.name}
                            </Link>
                          </Tag>
                        )
                      })}
                  </div>
                </div>
              }
              layout="vertical" bordered>
              <Descriptions.Item label="Номер записи">{data.response.id}</Descriptions.Item>
              <Descriptions.Item label="Гос.номер транспорта">{data.response.number}</Descriptions.Item>
              <Descriptions.Item label="Марка">{data.response.brand}</Descriptions.Item>
              <Descriptions.Item label="Модель">{data.response.model}</Descriptions.Item>
              <Descriptions.Item label="Вид транспорта">{data.response.className}</Descriptions.Item>
              <Descriptions.Item label="Цвет">{data.response.color}</Descriptions.Item>
              <Descriptions.Item label="VIN (в случае, номер шасси)">{data.response.vin}</Descriptions.Item>
              <Descriptions.Item label="Тип транспорта">{data.response.type}</Descriptions.Item>
              <Descriptions.Item label="Собственник">{data.response.owner}</Descriptions.Item>
              <Descriptions.Item label="Масса без нагрузки, кг">{data.response.weightWithoutLoad} кг</Descriptions.Item>
              <Descriptions.Item label="Максимальная разрешенная масса, кг">{data.response.permittedMaximumMass} кг</Descriptions.Item>
              <Descriptions.Item label="Колличество осей">{data.response.axlesNumber}</Descriptions.Item>
              {
                showAdditionalData &&
                  <>
                      <Descriptions.Item label={"Номер" + certificateTypeGenitive}>{data.response.certificateNumber}</Descriptions.Item>
                      <Descriptions.Item label={"Дата начала действия" + certificateTypeGenitive}>{data.response.dateExpirationCertificate}</Descriptions.Item>
                      <Descriptions.Item label={"Дата окончания действия" + certificateTypeGenitive}>{data.response.dateBeginningCertificate}</Descriptions.Item>
                      <Descriptions.Item label={"Литраж по" + certificateTypeDative}>{data.response.liter}</Descriptions.Item>
                  </>
              }
              <Descriptions.Item label = "Статус проверки">
                {data.response.verificationStatusText}
                {data.response.verificationStatus === 0 && hasPermission(Resource.Transport, Scope.Actions) && (
                  <>
                    <Button onClick={confirmVerification} type="primary" className={classes["action-btn"]} >подтвердить</Button>
                    <Button onClick={rejectVerification} danger className={classes["action-btn"]} >отклонить</Button>
                  </>
                )}
                {
                  (data.response.verificationStatus === VerificationStatus.AwaitsForScans) && customerContext.isCustomer() &&
                    <Button onClick={openCustomerEdit} type="primary" className={classes["action-btn"]}>
                        загрузить сканы СТС
                    </Button>
                }
              </Descriptions.Item>
            </Descriptions>
            {
              showAdditionalData &&
                <Descriptions style={{ marginTop: 10 }} layout="vertical" bordered>
                    <Descriptions.Item label="Секция 1">{data.response.section1}</Descriptions.Item>
                    <Descriptions.Item label="Секция 2">{data.response.section2}</Descriptions.Item>
                    <Descriptions.Item label="Секция 3">{data.response.section3}</Descriptions.Item>
                    <Descriptions.Item label="Секция 4">{data.response.section4}</Descriptions.Item>
                    <Descriptions.Item label="Секция 5">{data.response.section5}</Descriptions.Item>
                    <Descriptions.Item label="Секция 6">{data.response.section6}</Descriptions.Item>
                    <Descriptions.Item label="Секция 7">{data.response.section7}</Descriptions.Item>
                    <Descriptions.Item label="Секция 8">{data.response.section8}</Descriptions.Item>
                </Descriptions>
            }

            <Card style={{ marginTop: 10 }} title="Скан СТС">
              <PicturesUpload readonly fileList={fileList} />
            </Card>

            <Space className={classes.buttons}>
              <Button onClick={onBackHandler}>Назад к списку</Button>
            </Space>
          </>
        )}
      </Skeleton>
    </>
  )
}

export default TransportById
