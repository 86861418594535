import { FC, useState } from "react"

import { PlusOutlined } from "@ant-design/icons"
import { message, Modal, Upload, UploadFile } from "antd"
import { RcFile, UploadProps } from "antd/es/upload/interface"
import { uid } from "uid"

import { getBase64 } from "@/utils/getBase64"

const MAX_FILE_SIZE_MB = 10

const ACCEPTED_FILE_TYPES = [
  "image/jpeg", "image/png", "image/gif", "image/bmp", "image/webp",
  "application/pdf", "image/tiff", "application/x-djvu"
]

interface PicturesUploadProps {
  fileList?: UploadFile[];
  onChangeHandler?: (file: UploadFile | UploadFile[]) => void;
  maxCount?: number;
  readonly?: boolean;
}

export const PicturesUpload: FC<PicturesUploadProps> = (props) => {
  const { fileList, onChangeHandler, readonly, maxCount = 6 } = props

  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState("")
  const [previewTitle, setPreviewTitle] = useState("")

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    if (file.type == "application/pdf" && file.url) {
      let content = file.url.split("base64,")[1]

      //Преобразование URI в Blob
      const byteString = atob(content)
      const arrayBuffer = new ArrayBuffer(byteString.length)
      const int8Array = new Uint8Array(arrayBuffer)
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i)
      }

      const blob = new Blob([arrayBuffer], { type: "application/pdf" })
      const fileURL = URL.createObjectURL(blob)
      window.open(fileURL)
    }
    else {
      setPreviewImage(file.url || (file.preview as string))
      setPreviewOpen(true)
      setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1))
    }
  }

  const handleDownload = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    if (file.url == null) {
      message.open({
        key: file.name,
        type: "error",
        content: "Не удалось скачать файл.",
        duration: 3
      })

      return
    }

    let content = file.url.split("base64,")[1]

      //Преобразование URI в Blob
      const byteString = atob(content)
      const arrayBuffer = new ArrayBuffer(byteString.length)
      const int8Array = new Uint8Array(arrayBuffer)
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i)
      }

      const blob = new Blob([arrayBuffer], { type: file.type })
      const fileURL = URL.createObjectURL(blob)
      let tempLink = document.createElement("a")
      tempLink.href = fileURL
      tempLink.setAttribute("download", file.name)
      tempLink.click()
  }

  const handleCancel = () => setPreviewOpen(false)

  const beforeUpload = async (file: RcFile) => {
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      message.open({
        key: file.name,
        type: "error",
        content: `Максимальный размер файла ${MAX_FILE_SIZE_MB} Мб!`,
        duration: 3
      })

      return false
    }

    if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
      message.error("Неподдерживаемый формат файла!")
      return false
    }

    return true
  }

  const uploadHandler: UploadProps["customRequest"] = async (options) => {
    const { onSuccess, onError, file } = options
    try {
      const content = await getBase64(file as RcFile)
      onChangeHandler?.({
        url: content,
        name: (file as RcFile).name,
        uid: uid(),
        type: (file as RcFile).type
      })
      onSuccess?.("Ok")
    } catch (e: any) {
      onError?.(e)
    }
  }

  const removeHandler = (file: UploadFile) => {
    if (fileList?.length) {
      const newFileList = fileList.filter(item => item.uid !== file.uid)
      onChangeHandler?.(newFileList)
    }
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Добавить</div>
    </div>
  )

  return (
    <>
      <Upload
        fileList={fileList}
        accept=".png,.jpg,.jpeg,.gif,.bmp,.webp,.pdf,.tiff,.djvu"
        listType="picture-card"
        multiple
        maxCount={maxCount}
        onRemove={removeHandler}
        onPreview={handlePreview}
        onDownload={handleDownload}
        customRequest={uploadHandler}
        beforeUpload={beforeUpload}
        showUploadList={{
          showRemoveIcon: !readonly,
          showDownloadIcon: true,
          showPreviewIcon: true
        }}
      >
        {readonly || (fileList && fileList.length >= maxCount) ? null : uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  )
}
