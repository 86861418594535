import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react"

import { Alert, Button, Drawer, Form, Input, InputNumber, Row } from "antd"
import { useNavigate } from "react-router-dom"

import classes from "./CustomerCategoryAddForm.module.scss"

import { useCustomerCategoryMutation } from "@/hook/Dictionaries/Customers/useCustomerCategoryMutation"
import {
  useCustomerCategoryByIdQuery,
  useCustomerCategoryPercentSumQuery
} from "@/hook/Dictionaries/Customers/useCustomerCategoryQuery"

import {
  ICustomerCategoryCreate
} from "@/types/ICustomerCategory"

import { paths } from "@/router/paths"

import { formatNumber } from "@/utils/formatNumber"

export interface CustomerCategoryAddFormProps {
  open(id?: number): void

  close(): void
}

interface CustomerCategoryAddFormValues {
  name: string
  percent: number
}

export const CustomerCategoryAddForm = forwardRef<CustomerCategoryAddFormProps, {handleClose?: Function}>(
  (props, ref) => {
    const navigate = useNavigate()
    const [form] = Form.useForm<CustomerCategoryAddFormValues>()
    const [id, setId] = useState<number>(0)
    const [isOpen, setIsOpen] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)

    const { data: customerCategory } = useCustomerCategoryByIdQuery(id)
    const { data: percentSum } = useCustomerCategoryPercentSumQuery()
    const { createMutation, updateMutation } = useCustomerCategoryMutation()
    const [totalPercent, setTotalPercent] = useState<number>(0)

    useImperativeHandle(ref, () => ({
      open(id?: number) {
        onOpenHandler(id)
      },
      close() {
        onCloseHandler()
      }
    }))

    useEffect(() => {
      if (customerCategory && isOpen) {
        const response = customerCategory.response

        form.setFields([
          {
            name: "name",
            value: response.name
          },
          {
            name: "percent",
            value: response.percent
          }
        ])
      }
    }, [id, customerCategory, form, isOpen])

    useEffect(() => {
      if (percentSum && isOpen) {
        setTotalPercent(percentSum.response)
      }
    }, [percentSum, isOpen])

    const title = useMemo(() => {
      if (id) {
        return "Редактировать"
      }

      return "Добавить"
    }, [id])

    const onOpenHandler = (id?: number) => {
      if (id) {
        setId(id)
      }
      form.resetFields()
      setIsOpen(true)
    }

    const onLimitChange = (limit: number | null) => {
      if (customerCategory) {
        setTotalPercent((percentSum?.response ?? 0) - customerCategory.response.percent + (limit ?? 0))
      } else {
        setTotalPercent((percentSum?.response ?? 0) + (limit ?? 0))
      }
    }

    const onFinish = async (data: CustomerCategoryAddFormValues) => {
      if (totalPercent > 100)
        return

      try {
        const request: ICustomerCategoryCreate = {
          name: data.name,
          percent: data.percent
        }
        let navId = id
        setIsProcessing(true)

        if (id) {
          await updateMutation.mutateAsync({
            ...request,
            id: id
          })
        } else {
          const resp = await createMutation.mutateAsync(request)
          navId = resp.response
          navigate(paths.customerCategoryById(navId))
        }

        form.resetFields()
        onCloseHandler()
      } finally {
        setIsProcessing(false)
      }
    }

    const onCloseHandler = () => {
      setId(0)
      form.resetFields()
      setIsOpen(false)
      setTotalPercent(0)
      if(props.handleClose) {
        props.handleClose()
      }
    }

    return (
      <Drawer
        title={title}
        width={820}
        onClose={onCloseHandler}
        open={isOpen}
        styles={{ body: { paddingBottom: 80 } }}
        extra={
          <Button type="primary" disabled={isProcessing} onClick={form.submit}>
            Сохранить
          </Button>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            label="Наименование"
            name="name"
            rules={[
              {
                required: true,
                message: "Пожалуйста введите наименование"
              }
            ]}
          >
            <Input placeholder="Наименование" />
          </Form.Item>

          <Form.Item
            label="Процент объема топлива"
            name="percent"
            rules={[
              {
                required: true,
                message: "Пожалуйста введите процент объема топлива"
              }
            ]}
          >
            <InputNumber 
              addonAfter="%"
              placeholder="Процент объема"
              onChange={onLimitChange} />
          </Form.Item>
        </Form>
        <Row className={classes["total-percent"]}>
          <p>Общий процент объема: {formatNumber(totalPercent)}%</p>
        </Row>
          <Row hidden={totalPercent <= 100}>
              <Alert message="Общий процент объема не может превышать 100%" type="error" />
          </Row>
      </Drawer>
    )
  }
)
